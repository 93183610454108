@import "../../../../../sass/abstracts/mixins";

.row {
  @include font-medium-uppercase;

  color: $dark-grey;
  line-height: 1.25;
}

.status {
  @include font-size(12);
  background-color: $light-blue;
  border: 1px solid $primary;
  border-radius: 999px;
  color: $primary;
  display: inline-block;
  margin-left: 8px;
  padding: 4px 10px;

  html[data-theme="admin"] & {
    border-color: $primary-admin;
    color: $primary-admin;
  }
}

.subtitle {
  @include ellipsize-text;

  color: $black;
  padding-bottom: 2px;
}

.title {
  @include ellipsize-text;
  @include font-subheading;

  color: $black;
}

.titleRow {
  align-items: center;
  display: flex;
  padding-bottom: 2px;
}
