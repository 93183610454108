@import "../../../sass/abstracts/mixins";
@import "shared/src/sass/abstracts/variables";

.documentListItem {
  display: flex;
  align-items: center;
  border-top: 1px solid $light-grey-blue;
  border-bottom: none;
  height: rem-calc(56);
}

.titleAndId {
  display: flex;
  flex-direction: column;

  .title {
    color: $black;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 225px;

    @media (max-width: $breakpoint-extra-small) {
      max-width: 125px;
    }
  }
}

.dateCell {
  display: flex;
  justify-content: space-between;
  margin-left: auto;

  .date {
    display: flex;
    margin-right: 5px;
    margin-left: auto;
  }
}
