@import "shared/src/sass/abstracts/variables";

.subtitle {
  color: $black;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.15px;
  margin: 12px 0 24px 0;
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.26px;
  margin: 51px 0 0 0;
}
