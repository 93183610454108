@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.addButton {
  padding-left: 11px;
  padding-right: 11px;

  &Icon {
    margin-right: 6px;
  }
}

.centerRow {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.header {
  padding-bottom: 20px;
  padding-top: 24px;
}

.pageTitle {
  color: $black;
  flex-grow: 1;
  font-family: $font-family-body;
  font-size: rem-calc(24);
  font-weight: $font-weight-medium;
  letter-spacing: 0.26px;
  line-height: 0.82;
}

.table {
  margin-top: 20px;
}
