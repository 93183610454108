@import "../../../sass/abstracts/mixins";

.link {
  @include focus-indicator-appear;
  @include focus-indicator-disappear;
  @include reset-a;

  color: $dark-grey;
  font-size: rem-calc(10);
  line-height: 1.125;
  text-align: center;

  &:focus,
  &:hover {
    color: $extra-dark-grey;
  }
}

.logo {
  margin: 35px 0 7px 20px;

  & a {
    display: inline-block;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  min-width: 245px;

  @media screen and (min-width: $breakpoint-medium) {
    min-width: 264px;
  }

  @media screen and (min-width: $breakpoint-extra-large) {
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.05);
    overflow-y: auto;
  }
}

.navContent {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.navHeader {
  align-items: center;
  color: $dark-grey;
  display: flex;
  font-size: rem-calc(12);
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 0.875;
  margin: 29px 0 0 20px;
  padding-left: 25px;
  text-transform: uppercase;

  @media screen and (min-width: $breakpoint-extra-large) {
    height: 48px;
    margin: 0;
  }
}

.privacyTermsWrapper {
  align-items: flex-end;
  display: flex;
  padding: 20px 20px 10px 20px;

  @media screen and (min-width: $breakpoint-extra-large) {
    justify-content: center;
  }
}

.userProfileInfo {
  margin: 0 20px;
}
