.toastContainer {
  margin-top: 64px;
  z-index: 500;
}

@media only screen and (min-width: 480px) {
  .toastContainer {
    width: 460px;
  }
}

@media only screen and (min-width: 640px) {
  .toastContainer {
    width: 600px;
  }
}
