@import "../../../../../sass/abstracts/mixins";

.documentHeader {
  text-align: left;
  display: flex;
  width: 100%;
  padding-bottom: 18px;
  flex-direction: row;
  justify-content: space-between;
}

.titleRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2px;
}

.title {
  margin: 0;
  padding: 0;
  font-size: rem-calc(20);
}

.status {
  padding: 1px 8px;
  margin: 0px 0px 0px 8px;
  color: $primary;
  font-size: rem-calc(12);
  width: 85px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }

  &.new {
    color: $primary;
    
    html[data-theme="admin"] & {
      color: $primary-admin;
    }
  }

  &.inProgress {
    color: $warning;
  }

  &.submitted {
    color: $success;
  }
}

.commonSubtitleStyles {
  padding: 0px;
  margin: 0px;
}

.subTitle1 {
  padding: 0px;
  margin: 0px;
  color: $black;
}

.subTitle2 {
  padding: 0px;
  margin: 0px;
  color: shade($black, 60%);
}

.icon {
  cursor: pointer;
  font-size: rem-calc(20);
  color: $dark-grey;
}
