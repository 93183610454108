@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.button {
  align-items: center;
  border-radius: 4px;
  border: none;
  display: inline-flex;
  flex-direction: row;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;

  background-color: $primary;
  color: $white;

  padding: 8px 35px;

  html[data-theme="admin"] & {
    color: $white;
    background-color: $primary-admin;
  }
}

.buttonDisabled {
  background-color: rgba($black, 0.12);
  color: rgba($black, 0.26);

  html[data-theme="admin"] & {
    background-color: rgba($black, 0.12);
    color: rgba($black, 0.26);
  }
}

.buttonLoading {
  padding: 8px 35px 8px 14px;
}

.buttonText {
  flex-shrink: 0;
}

.textButton {
  align-items: center;
  border-radius: 4px;
  border: none;
  display: inline-flex;
  flex-direction: row;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  position: relative;
  text-transform: uppercase;

  background-color: transparent;
  color: $primary;

  padding: 8px 35px;

  html[data-theme="admin"] & {
    background-color: transparent;
    color: $primary-admin;
  }
}

.textButtonCancel {
  color: $error;
}

.textButtonDisabled {
  color: $medium-grey;
}

.textButtonLoading {
  padding: 8px 35px 8px 14px;
}

.textButtonLink {
  text-decoration: underline;
  font-weight: 400;
  text-transform: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
}

.loader {
  margin-right: 0.6rem;
  padding: 0;
}
