@import "../../../sass/abstracts/functions";
@import "../../../sass/abstracts/variables";

.profileCard {
  background-color: $primary;
  width: 100%;
  min-height: 181px;
  padding: 36.5px 24px;

  html[data-theme="admin"] & {
    background-color: $primary-admin;
  }

  @media screen and (min-width: $min-desktop) {
    border-radius: 4px;
  }

  @media screen and (max-width: $max-tablet) {
    border-bottom: 8px solid $light-white;
  }
}

.adfs {
  margin-bottom: 25.5px;
}

.card {
  max-width: 1280px;
}

.star {
  margin-right: 5px;
  margin-top: 2.5px;
  font-size: 16px;
}

.icon {
  margin-right: 8px;
  font-size: 1.35rem;
  background-color: $white;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userName {
  font-size: rem-calc(20);
  color: $white;
  margin-bottom: 5px;
  letter-spacing: 0.63px;

  @media screen and (min-width: $min-desktop) {
    font-size: rem-calc(36);
    margin-right: 10px;
  }
}

.userName_desktop {
  margin-bottom: 12px;
}

.email {
  color: $white;
  font-size: 1rem;
  font-weight: 400 im !important;
  letter-spacing: 0.28px;
  margin-bottom: 0;
}

.group {
  color: $white;
  font-size: 1rem;
  font-weight: 400 im !important;
  letter-spacing: 0.28px;
  margin-bottom: 24;
}

.formcounts {
  display: flex;
}

.formcounts_desktop {
  flex-direction: column;
}

.formCount {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.formCount_desktop {
  margin-bottom: 16px;
}

.groupRow {
  display: flex;
  flex-shrink: 1;
  flex-grow: 100%;
}

.count {
  color: $white;
  font-size: rem-calc(20);
}

.count_desktop {
  text-decoration: underline;
}

.clickable {
  border: none;
  background: none;
}

.row {
  display: flex;
}

.leftTop {
  display: flex;
  margin-right: 150px;
}

.groupCol {
  margin-right: 48px;
  max-width: 100%;
}

.sectionLabel {
  color: $white;
  font-size: rem-calc(12);
  font-weight: 500;
  letter-spacing: 1.88px;
  margin-bottom: 10px;
}

.supervisor {
  color: $white;
  font-size: 1rem;
  font-weight: 400 !important;
  letter-spacing: 0.28px;
}
