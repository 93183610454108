@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/variables";

.widgetContainer {
  height: 264px;
  min-width: 328px;
  border: 1px solid rgba($black, 0.1);
  border-radius: 4px;
  padding: 13px;
}

.iconWrapper {
  margin-top: 1rem;
  span {
    display: block;
  }
}

.chartContainer {
  height: 80%;
  width: 100%;
}
