@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/mixins";
.body {
  text-align: left;
}

.description {
  color: #000000de;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.26px;
}

.title {
  color: #000000de;
  font-weight: 500;
  letter-spacing: 0.26px;
  margin-bottom: 3px;
}

.buttonWrapper {
  margin-top: 0;
  margin-bottom: 1.05rem;
}

.submitButton {
  border-radius: 4px;
  color: $primary;
  text-transform: uppercase;
  background-color: $primary;
  margin-top: 0 !important; // This specific instance in rehuddle has no margin
  height: 56px;

  html[data-theme="admin"] & {
    color: $primary-admin;
    background-color: $primary-admin;
  }
  
  @media (max-width: $max-phone) {
    font-size: rem-calc(14);
  }
}

.cancelButton {
  background-color: $white;
  text-transform: uppercase;
  margin-top: 26px; // Rehuddle button has smaller margin
  @media (max-width: $max-phone) {
    font-size: rem-calc(14);
  }
}
