@import "../../../sass/abstracts/mixins";


.titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  box-shadow: 0px 1px 3px $light-grey;
  padding-bottom: 1.05rem;
  padding-top: 1.05rem;
}

.title {
  padding: 4px 0px;
  margin: 0;
  color: $black;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.26px;
  // Ellipsis
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-self: center;
  text-align: center;
  flex-grow: 1;
}

.icon {
  cursor: pointer;
  font-size: rem-calc(20);
  color: $dark-grey;
}

.iconContainer {
  position: absolute;
  right: 0%
}

.iframe {
  // calculation included with title bar and padding/margin of content
  height: calc(100svh - 3.35rem - 50px); 
  border: 0;
}