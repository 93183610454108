@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.sidebarDrawerHeader {
  color: $black;
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.26px;
}

.sidebarDrawerBody {
  color: $black;
  font-size: rem-calc(14);
  letter-spacing: 0.24px;
}
