@import "shared/src/sass/abstracts/mixins";

.oeWrapperError {
  border: 2px solid $error;
  padding: 20px;
}

.operationalExperiences {
  @media (min-width: $min-desktop) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
  }
}

.oeHeader {
  display: flex;
  flex-direction: column;
}

.requiredText {
  margin: 20px 0;
  padding: 0;
  color: $error;
  font-size: rem-calc(20);
  text-align: center;

  &.met {
    color: $success;
  }
}

.oeCard {
  background-color: $white;
  padding: 1.5rem;
  border: 1px solid $light-grey;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  min-width: 0;

  @media (min-width: $min-desktop) {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 32%;
  }

  &.included {
    background-color: $active-fill;

    html[data-theme="admin"] & {
      background-color: $primary-fill-admin;
    }
  }

  .title {
    font-size: rem-calc(20);
    letter-spacing: rem-calc(0.26);
  }

  .label {
    color: $dark-grey;
    font-size: rem-calc(14);
    letter-spacing: rem-calc(0.24);
  }

  .description {
    &.collapsed {
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }

  .html {
    color: $dark-grey;
    letter-spacing: rem-calc(0.25);
    font-size: rem-calc(14);
    max-width: 100%;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .actionRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  .showMore {
    margin-bottom: 0;
    margin-top: 1.25rem;
    justify-content: center;
    position: relative;

    .showMoreBtn {
      display: flex;
      align-items: center;
      border: 1px solid $medium-grey;
      border-radius: 0.25rem;
      color: $primary;
      padding: 0.5rem rem-calc(45);
      background-color: transparent;

      html[data-theme="admin"] & {
        color: $primary-admin;
      }
    }

    .showMoreIcon {
      margin-left: 0.25rem;
      transform: rotate(180deg);
      font-size: 1.25rem;
    }

    &.collapsed {
      .showMoreIcon {
        transform: none;
      }

      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: rem-calc(-50);
        width: 100%;
        height: rem-calc(50);
        background: linear-gradient(180deg, rgba($black, 0) 0%, $white 75%);
      }
    }
  }
  &.included .showMore::before {
    background: linear-gradient(180deg, rgba($black, 0) 0%, $active-fill 75%);
  }

  .includeBtn {
    border: 1px solid $black;
    border-radius: rem-calc(100);
    font-size: rem-calc(14);
    padding: 0.25rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black;
    background-color: $white;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: normal;

    &.included {
      border-color: $primary;
      color: $white;
      background-color: $primary;

      html[data-theme="admin"] & {
        border-color: $primary-admin;
        color: $white;
        background-color: $primary-admin;
      }
    }

    &:hover {
      cursor: pointer;
    }

    .loader,
    .includedIcon {
      position: relative;
      left: -0.5rem;
    }
  }
}

.reshuffle {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  text-align: center;

  .text {
    margin-top: 0.5;
    font-size: rem-calc(14);
    color: $dark-grey;
  }
}
