@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/mixins";

.sidebarDrawerWrapper {
  box-shadow: 0px 1px 3px $light-grey;
  line-height: 1.5rem;
  margin-bottom: 16px;
  padding-bottom: 1.05rem;
  padding-top: 1.05rem;
  position: absolute;
  text-align: center;
  height: 56px;
  left: 0;
  top: 0;
}

.stickySidebarDrawerWrapper {
  background-color: $white;
  padding-left: 18px;
  padding-right: 18px;
  position: sticky;
  width: auto;
}

.sidebarDrawerHeader {
  color: $black;
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.26px;
}

.sidebarDrawerBody {
  color: $black;
  font-size: rem-calc(14);
  letter-spacing: 0.24px;
}

.sidebarDrawerContent {
  margin-top: 56px;
  padding-left: 18px;
  padding-right: 18px;
  text-align: left;
}

.buttonWrapper {
  margin-top: 0;
  margin-bottom: 1.05rem;
}

.error {
  margin-bottom: 1rem;
}

.submitButton {
  border-radius: 4px;
  text-transform: uppercase;
  background-color: $primary;
  margin-top: 0 !important; // This specific instance in rehuddle has no margin
  height: 56px;

  html[data-theme="admin"] & {
    background-color: $primary-admin;
  }

  @media (max-width: $max-phone) {
    font-size: rem-calc(14);
  }
}

.cancelButton {
  background-color: $white;
  text-transform: uppercase;
  margin-top: 26px; // Rehuddle button has smaller margin
  @media (max-width: $max-phone) {
    font-size: rem-calc(14);
  }
}
