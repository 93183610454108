@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/mixins";

$column-border: $very-light-grey;
$secondary-border: #F3F6F9;
$level-2-text: #999999;
$head-border: #0087F4;
$row-background: $white;
$row-background-alt: #FBFDFF;

.body {
  border-bottom: 1px solid $column-border;
  position: relative;
  z-index: 1;

  & td {
    text-align: center;
  }

  & td:nth-child(even) {
    border-left: 1px solid $secondary-border;
  }

  & td:nth-child(odd) {
    border-left: 1px solid $column-border;
  }

  & th {
    color: $dark-grey;
    font-family: $font-family-body;
    font-size: rem-calc(14);
    font-weight: 600;
    height: 74px;
    left: 0;
    line-height: 1.4286;
    padding: 8px 16px;
    position: sticky;
    text-align: left;
  }

  & th::before {
    border-left: 1px solid $column-border;
    border-right: 1px solid $column-border;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  & tr {
    border-right: 1px solid $column-border;
    border-bottom: 1px solid $secondary-border;
  }

  & tr:last-child {
    border-bottom: none;
  }

  & tr:nth-child(even) {
    background-color: $row-background-alt;

    & th {
      background-color: $row-background-alt;
    }
  }

  & tr:nth-child(odd) {
    & th {
      background-color: $row-background;
    }
  }
}

.headerGroup {
  position: relative;
  z-index: 20;

  & .pin {
    left: 0;
    z-index: 21;
  }
}

.level1Headers {
  & td, th {
    background-color: $primary;
    position: sticky;
    top: 0;

    html[data-theme="admin"] & {
      background-color: $primary-admin;
    }
  }

  & th {
    color: $white;
    font-family: $font-family-body;
    font-size: rem-calc(12);
    font-weight: 500;
    line-height: 1.3333;
    letter-spacing: 1.5px;
    padding: 13px 8px;
    text-transform: uppercase;
  }

  & th::before, td::before {
    border-right: 1px solid $head-border;
    border-top: 1px solid $head-border;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    html[data-theme="admin"] & {
      border-right: 1px solid $primary-admin;
      border-top: 1px solid $primary-admin;
    }
  }

  & td::before {
    border-left: 1px solid $head-border;

    html[data-theme="admin"] & {
      border-left: 1px solid $primary-admin;
    }
  }
}

.level2Headers {
  & td {
    width: 97px;
  }

  & td, th {
    background-color: $row-background;
    position: sticky;
    top: 74px;
  }

  & .pin::before {
    border-left: 1px solid $column-border;
    border-right: 1px solid $column-border;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  & th {
    color: $level-2-text;
    font-family: $font-family-body;
    font-size: rem-calc(12);
    font-weight: 400;
    letter-spacing: 0.24px;
    line-height: 1.3333;
    padding: 5px;
    width: 49px;
  }

  & th:last-child {
    border-right: 1px solid $column-border;
  }

  & th:nth-child(even) {
    border-left: 1px solid $secondary-border;
  }

  & th:nth-child(odd) {
    border-left: 1px solid $column-border;
  }
}

.table {
  border-collapse: collapse;
  table-layout: fixed;

  & td, th {
    background-clip: padding-box;
  }
}
