@import "shared/src/sass/abstracts/variables";

.mainContent {
  flex: 1;
  min-width: 320px;
  overflow-x: auto;
}

.navbarWrapper {
  display: flex;
  flex-direction: row;
  height: calc(100% - 64px);
  overflow: hidden;

  @media (min-width: $breakpoint-large) {
    margin-top: 64px;
  }
}
