@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.alwaysOption {
  padding: rem-calc(6) 0;
}

.settingsForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: $white;
  border: 1px solid $medium-grey;
  border-radius: 4px;
  padding: 2rem 2rem;
}

.editTimeframeOption {
  display: flex;
  align-items: center;
}

.timeLimitInput {
  width: rem-calc(64);
  //height: rem-calc(32);
  margin: 0 rem-calc(4);

  input[type="number"] {
    width: 2.75rem;
  }

  :nth-child(1n) .MuiInputBase-input {
    text-align: center;
  }
}

// Select
.timeLimitUnit {
  width: rem-calc(96);
  margin: 0 rem-calc(4);
  //height: rem-calc(32);

  :nth-child(1n) .select {
    height: auto !important;
    padding: 0.5rem !important;
  }
}

.timeToInputContainer {
  margin-left: rem-calc(8);
}

// TextInput
.timeToInput {
  width: 125px;
  position: relative;
  margin: 0 rem-calc(4);

  :nth-child(1n) .MuiInputBase-input {
    text-align: center;
  }

  :nth-child(1n) input [type="time"]::-webkit-clear-button {
    display: none;
  }

  :nth-child(1n) input [type="time"]::-webkit-inner-spin-button {
    position: absolute;
    right: 0;
  }
}

.errorText {
  display: block;
  color: $error;
  font-size: rem-calc(16);
  margin: rem-calc(2) rem-calc(5) 0 1rem;
}
