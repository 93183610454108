@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

/** Title: Company Info **/

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.h1 {
  font-weight: 400;
  font-size: rem-calc(36);
  letter-spacing: rem-calc(0.63);
}

/** Company Logo **/

.logo {
  display: flex;
  align-items: center;
}

.primaryLogoWrapper {
  display: flex;
  width: 239px;
  height: 239px;
  border: $light-grey 3px solid;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  margin-right: 34px;

  img {
    max-width: 225px;
    max-height: 225px;
  }
}

.condensedLogoWrapper {
  display: flex;
  width: 148px;
  height: 148px;
  border: $light-grey 3px solid;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 55px;
  margin-right: 70px;

  img {
    max-width: 150px;
    max-height: 30px;
  }
}

// hides input file button
.inputFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.fileButton {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: row;
  font-weight: 400;
  letter-spacing: 1.25px;
  margin: 1rem 0 1rem 15px;
  padding: 8px 35px;
  position: relative;
  height: rem-calc(48);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  background-color: $primary;
  color: $white;

  html[data-theme="admin"] & {
    background-color: $primary-admin;
  }

  &:hover {
    background-color: $secondary;
    cursor: pointer;

    html[data-theme="admin"] & {
      background-color: $secondary-admin;
    }
  }
}

/** Company Address **/

.stateAndZip {
  display: flex;
}

.companyAddress > * {
  padding-bottom: 10px;
}

.stateDropdown {
  width: 50%;
  margin-right: 11px;
}
