@import "../../sass/abstracts/mixins";
@import "shared/src/sass/abstracts/variables";

.dashboard {
  background-color: $snow;
  flex: 1;
  min-height: 100%;
  min-width: 100%;
  padding: 22px;

  @media (min-width: $breakpoint-large) {
    padding: 34px;
  }
}

.grid {
  display: grid;
  grid-template-areas:
    "welcome"
    "reports"
    "recent";
  grid-template-columns: 1fr;
  max-width: 1200px;
  column-gap: 5%;
  row-gap: 17px;

  @media (min-width: $breakpoint-large) {
    grid-template-areas:
      "welcome welcome"
      "reports recent";
    grid-template-columns: 1fr 1fr;
    row-gap: 34px;
  }
}

.recentArea {
  grid-area: recent;
}

.reportsArea {
  grid-area: reports;
}

.welcomeArea {
  display: flex;
  flex-direction: row;
  flex: 1;
  grid-area: welcome;
}

.welcomeUserContainer {
  flex-grow: 1;
  flex-shrink: 1;
}
