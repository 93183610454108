@import "../../sass/abstracts/mixins";

$border-width: 1px;

.root .input {
  @include font-body-regular;

  color: $black;

  &::placeholder {
    color: $dark-grey-blue;
    opacity: 1;
  }

  &Expanded {
    padding: 8px !important;
  }

  &NotExpanded {
    padding: 0 !important;
  }
}

.rootDisabled .input {
  color: $dark-grey-blue;
}

.root .notchedOutline {
  @include focus-indicator-disappear($border-width);

  border-color: $dark-grey;
  border-radius: 4px;
  border-style: solid;
  border-width: $border-width;
}

.root .notchedOutline.notchedOutlineError {
  border-color: $error;
}

.root:global(.Mui-focused) .notchedOutline.notchedOutlineKeyboardFocused {
  @include focus-indicator-appear-external-focus($border-width);

  border-color: $yellow;
}

.root .notchedOutline.notchedOutlineMouseBlurred {
  transition: none;
}

.root:global(.Mui-focused) .notchedOutline.notchedOutlineMouseFocused {
  @include primary-outline-appear($border-width);

  border-color: $primary;
  transition: none;

  html[data-theme="admin"] & {
    border-color: $primary-admin;
  }
}

.root:global(.Mui-focused) .notchedOutline {
  border-width: 1px !important;
}

.root {
  &Disabled {
    background-color: $light-grey;
  }

  &Expanded {
    padding: 8px !important;
  }

  &Error {
    background-color: $error-fill;
  }

  &NotExpanded {
    padding-bottom: 16px !important;
    padding-left: 16px !important;
    padding-top: 16px !important;
  }
}
