@import "shared/src/sass/abstracts/mixins";

.ownerInitials {
  width: 175px !important;
  display: inline-block;
  // Ellipsis
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.documentList {
  position: relative;
  top: -20px;
  list-style: none;
  width: 100%;
  padding: 0px;
}

.documentListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $light-grey;

  &:hover {
    cursor: pointer;
    background-color: $light-grey;
  }

  &.tableHeader {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;

    &:hover {
      cursor: default;
      background-color: transparent;
    }
  }
}

//Deprecated
.newDocIcon {
  color: $white;
  background-color: $primary;
  width: rem-calc(40);
  height: rem-calc(40);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: rem-calc(4);
  font-size: 2rem;
  margin-right: 0.75rem;

  html[data-theme="admin"] & {
    background-color: $primary-admin;
  }
}

.left {
  text-align: center;
  width: 75px;
}

.middle {
  flex-grow: 1;
  flex-shrink: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 0.75rem;
}

.secondaryText {
  color: $dark-grey;
  font-size: rem-calc(14);
  justify-content: space-between;
  display: flex;
}

.menuBtn {
  background: none;
  padding: rem-calc(12);
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  & i {
    color: $dark-grey;
    font-size: 1.5rem;
    margin: 0;
    position: relative;
    top: 2px;
  }
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
}

.documentInfo {
  display: flex;
  justify-content: space-between;

  .titleAndSubtitle {
    display: flex;
    flex-direction: column;
    color: $eerie-black;
    
    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    @media screen and (max-width: 585px) {
      span {
        width: 50vw;
      }    
    }

    @media screen and (max-width: 450px) {
      span {
        width: 40vw;
      }    
    }
    
    .title {
      font-size: 14px;
      font-weight: 500;
    }

    .subtitle {
    font-size: 14px;
      color: $dark-grey;
    }

    .workOrderId {
      color: $dark-grey;
      font-size: 11px;
    }
  }
}

.statusDate {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.244px;
  color: $dark-grey;
  justify-content: center;

  .status {
    font-size: 13px;
    font-weight: 700;

    .new & {
        color: $primary;
        
        html[data-theme="admin"] & {
          color: $primary-admin;
        }
    }

    .inProgress & {
        color: $warning;
    }

    .submitted & {
        color: $success;
    }
  }

  .date {
    font-size: 11px;
  }
}