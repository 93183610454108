@import "shared/src/sass/abstracts/variables";

.labelWrapper {
  border: none;
  padding: 5px 0;
  &:hover {
    border: none;
  }
}

.labelInput {
  border: 1px solid $dark-grey;
  padding: 5px;
  &:focus {
    border: 1px solid $dark-grey;
  }
}

.iconSelector {
  border: 2px solid $primary;
  background-color: $active-fill;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;

  html[data-theme="admin"] & {
    background-color: $primary-fill-admin;
    border: 2px solid $primary-admin;
  }
}

.iconSearchWrapper {
  padding: 5px;
  margin-bottom: 16px;
}

.iconSelectionArea {
  border: none;
  width: 100%;
}

.iconPicker {
  border: 1px solid;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  
  svg {
    width: 20px;
    height: 20px;
  }
}

.expanded {
  border-color: $primary;

  html[data-theme="admin"] & {
    border-color: $primary-admin;
  }
}

.closed {
  border-color: $dark-grey;
}