@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/mixins";
.body {
  text-align: left;
}

.navLabel {
  font-size: rem-calc(14);
  color: $dark-grey;
  font-weight: 400;
  letter-spacing: rem-calc(0.24);
}
