@import "shared/src/sass/abstracts/variables";

.apiDSButtonsWrapper {
  display: flex;
  margin: 12px 0 0 0;
}

.buttonMargin {
  margin-left: 15px;
}

.checkboxField {
  margin-bottom: 24px;
  margin-top: 12px;
}

.successMessage {
  color: $success;
  letter-spacing: 0.15px;
  line-height: 1.357;
  margin: 4px 0 0 16px;
}
