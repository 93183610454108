@import "../../../../sass/abstracts/variables";
@import "../../../../sass/abstracts/functions";
@import "../../../../sass/abstracts/mixins";

.input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  min-height: 51px;
  padding: rem-calc(6) 0.5rem;
  border: 1px solid $dark-grey;
  border-radius: rem-calc(4);

  &.checked {
    background: $active-fill;
    border-color: $primary;

    html[data-theme="admin"] & {
      background: $primary-fill-admin;
      border-color: $primary-admin;
    }
  }

  &.error {
    border-color: $error;
    background: $error-fill;

    label {
      color: $error;
    }
  }

  &:focus {
    border-color: $focus-indicator-outline;
    box-shadow: 0 0 0 3px $focus-indicator-outline;
  }
}

.commentField {
  margin-left: 2rem;
}
