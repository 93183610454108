@import "shared/src/sass/abstracts/variables";

.sectionsDrawer {
  background-color: $active-fill;

  html[data-theme="admin"] & {
    background-color: $primary-fill-admin;
  }

  .sectionsTabWrapper {
    display: none;
  }
}

.mainNav {
  width: 264px;
  flex-shrink: 0;
  border-right: 1px solid $active-fill; 
  background-color: $active-fill;

  html[data-theme="admin"] & {
    background-color: $primary-fill-admin;
    border-right-color: $primary-fill-admin;
  }
}

@media screen and (max-width: $breakpoint-large) {
  .sectionsDrawer {
    position: absolute;
    height: 100vh;
    z-index: 500;
    left: -264px;
    background-color: $active-fill;

    html[data-theme="admin"] & {
      background-color: $primary-fill-admin;
    }

    &.active {
      display: block;
      transform: translate(0%, 0);
      animation-delay: 0s;
      animation-play-state: running;
      animation-iteration-count: 1;
      animation-duration: 0.5s;
      animation-name: drawerAnimation;
      left: 0;

      .sectionsTabWrapper {
        .sectionsIcon {
          fill: $white;
        }
        .sectionsTab {
          background-color: $primary;
          color: $white;

          html[data-theme="admin"] & {
            background-color: $primary-admin;
          }
        }
      }
    }

    &.errors {
      .sectionsTab {
        animation: glow 1s infinite alternate;
      }
    }
    
    .mainNav {
      position: absolute;
      height: 100vh;
      z-index: 500;
    }

    .sectionsTabWrapper {
      display: flex;
      position: absolute;
      top: 115px;
      left: 250px;
      cursor: pointer;
      z-index: 500;

      .sectionsIcon {
        fill: $dark-grey;
      }

      .sectionsTab {
        background-color: $white;
        border: 1px solid $light-grey-blue;
        color: $dark-grey;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        display: block;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 1.88px;
        line-height: 1.16667;
        margin: 27px 0 0 -1px;
        padding: 13px 27px;
        position: sticky;
        text-transform: uppercase;
        top: 0;
        width: 30px;
        writing-mode: vertical-lr;
      }
    }
  }
}

.navContainer {
  position: relative;
  border-top: 1px solid $light-grey;
  max-height: calc(100vh - 115px);
  overflow-y: auto;
}

.loadingContainer {
  display: flex;
  justify-content: center;
}

.navItem {
  display: flex;
  width: 264px;
  padding: 15px 25px;
  align-items: center;
  gap: 20px;
  color: $primary;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }

  &.goBack {
    cursor: pointer;
  }

  .progressDot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    border-radius: 50%;
    background: $primary;
    color: $white;
    font-size: 10px;
    font-weight: 700;

    html[data-theme="admin"] & {
      background: $primary-admin;
    }
  }
  
  .text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .verticalProgressBar {
    background-color: $primary;
    width: 4px;
    height: 26px;
    border-radius: 15px;
    position: absolute;
    margin-top: 47px;
    margin-left: 7px;

    html[data-theme="admin"] & {
      background-color: $primary-admin;
    }

    &.disabled {
      display: none;
    }
  }

  &.disabled {
    opacity: 40%;
    cursor: not-allowed;
  }

  &.selected {
    background-color: $primary;
    color: $white;
    cursor: default;

    html[data-theme="admin"] & {
      background-color: $primary-admin;
      color: $white;
    }

    .progressDot {
      background-color: $white;
      color: $primary;

      html[data-theme="admin"] & {
        background-color: $white;
        color: $primary-admin;
      }
    }

    &.error {
      background-color: $error;
      color: $white;
    }
  }
  &.error {
    color: $error;

    .progressDot {
      background-color: $error-fill;
      color: $error;
    }
  }
  
}


@keyframes drawerAnimation {
  from {
    transform: translate(-100%, 0);
    left: -264px;
  }

  to {
    transform: translate(0%, 0);
    left: 0;
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 3px -3px $error;
  }

  to {
    box-shadow: 0 0 3px 3px $error;
  }
}