@import "../../sass/abstracts/variables";

.searchBarWrapper {
  width: 100%;
}

.searchIcon {
  color: $dark-grey;
  font-size: 24px;
  font-weight: $font-weight-regular;
  padding-left: 12px;
  position: relative;
  top: 2px;
}

.noPaddingLeft {
  padding-left: 0px;
}

.searchInput {
  background-color: $white;
  height: 36px;
  padding: 4.5px 2px;
  color: $dark-grey;
  border-radius: 0;
  ::placeholder {
    opacity: 1;
  }
  border: 0px;

  &:hover {
    border: 0px;
  }
  &:focus-within {
    border-bottom: 1px solid $light-grey;
    border-radius: 0;
    &:hover {
      border-bottom: 1px solid $dark-grey;
    }
  }
}

.filtersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clearSearch {
  align-items: center;
  color: $error;
  font-weight: $font-weight-medium;
  cursor: pointer;
  display: flex;
}

.rounded {
  border-radius: 18pt;
}

.underline {
  &:after {
    border-bottom: 1px solid $light-grey;
  }
}

.filter {
  color: $primary;
  font-weight: $font-weight-medium;
  cursor: pointer;
  margin-left: 1rem;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }
}
