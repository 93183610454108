@import "../../../../sass/abstracts/variables";

.moreOptionsLoader {
  position: relative;
  justify-content: center;
  align-items: center;
  background: $white;
  display: flex;

  .borderLine {
    position: absolute;
    top: calc(50% - 1px);
    height: 1px;
    width: 100%;
    background: $primary;

    html[data-theme="admin"] & {
      background: $primary-admin;
    }
  }

  .moreOptionsButton {
    background: $white;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
    z-index: 1;

    button {
      padding: 0 6px;
      text-decoration: none;
    }

    .icon {
      color: $primary;

      html[data-theme="admin"] & {
        color: $primary-admin;
      }
    }
  }
}
