@import "shared/src/sass/abstracts/variables";

.photoAreaWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 25px 25px 25px;

  background: $light-blue;
  border: 1px solid $primary;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 13px;

  html[data-theme="admin"] & {
    background: $primary-fill-admin;
    border-color: $primary-admin;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.visible {
  position: relative;
  z-index: 500; // covers DocumentHeader
}

.hidden {
  display: none;
}

.closeButton {
  position: absolute;
  opacity: 0.5;
  font-size: 45px;
  transition: all .3s ease-out;
  cursor: pointer;
  top: -20px;
  right: 20px;
  padding: 15px;

  &:hover {
    opacity: 0.75;
    transform: scale(1.1);
    color: $white;
  }
}

:global(.image-gallery-content .image-gallery-slide .image-gallery-image) {
  max-height: calc(100vh - 248px) !important;
}