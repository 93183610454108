@import 'shared/src/sass/abstracts/mixins';

.appliedFilters {
  display: none;

  @media screen and (max-width: $max-phone) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    gap: 5px;
    color: $primary;

    html[data-theme="admin"] & {
      color: $primary-admin;
    }

    .filterPill {
      display: flex;
      background: $active-fill;
      padding: 5px 10px;
      border: 1px solid $primary;
      display: inline-flex;
      border-radius: 15px;
      cursor: pointer;
      align-items: center;
      gap: 5px;
      
      html[data-theme="admin"] & {
        background: $primary-fill-admin;
        border: 1px solid $primary-admin;
      }

      span {
        text-transform: capitalize;
      }
    }

    .iconContainer {
      display: flex;
    }
  }
}

.searchFiltersContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.5rem;

  .filter {
    margin-bottom: 10px;
    flex: 1 0 32%;
    

    .select {
      font-size: 14px;
    }

    &.searchInput {
      border: 1px solid $grey-100;
      border-radius: 5px;
      padding: 5px 0;
      font-size: 13px;

      &:focus-within {
        border: 1px solid $primary;

        html[data-theme="admin"] & {
          border: 1px solid $primary-admin;
        }

        div {
          border: none;
        }
      }

      input {
        padding: 0 10px;
      }
    }
  }

  .owner {
    border: 0;
    padding: 4px 0;

    &:hover {
      border: 0;
    }

    &:focus-within {
      border-bottom: 1px solid $light-grey;
      border-radius: 0;

      &:hover {
        border-bottom: 1px solid $dark-grey;
      }
    }
  }

  .dateRange {
    margin-top: -7px;

    div {
      margin: 0;
      gap: 10px;
    }

    span {
      padding: 0;  
    }

    button {
      font-size: 14px;
      padding: 6px 10px;
      border-color: $very-light-grey;
    }

    .containerPhone {
      flex-direction: column;
      margin-top: 10px;

      button {
        display: flex;
        flex: 1;
        width: 100%;
      }
    }
  }

  .newRow {
    display: flex;
    flex-basis: 100%;
  }
}