@import "../../sass/abstracts/_variables.scss";

.contentWrapper {
  padding: 0 15px;
  height: 100%;
  width: 100%;

  @media screen and (max-width: $max-phone) {
    padding: 0;
  }
}

// @media print {
//   .contentWrapper {
//     break-before: always;
//   }
// }

.responsiveContentWrapper {
  max-width: 990px;
  padding: 0 15px;

  @media (max-width: $max-tablet) {
    padding: 0 15px 100px 15px; // For bottom navigation
  }
}
