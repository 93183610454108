@import "shared/src/sass/abstracts/mixins";

.carouselContainer {
  position: relative;
  width: 100%;
}

.carouselOverflow {
  cursor: grab;

  :active {
    cursor: grabbing;
  }
}

.carousel {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 1rem 0;
  align-items: flex-start;
  user-select: none;
  background-color: $snow;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    background: transparent;
    display: none;
  }
}

.carouselFade {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 100%;
  background: linear-gradient(to right, transparent, white);
  pointer-events: none;
}
