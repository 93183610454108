@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/variables";

.breadcrumbWrapper {
  border-bottom: 1px solid $light-grey;
  padding-bottom: 13px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: 100%;

  ol {
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    li div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .crumb {
    color: $dark-grey;
    font-size: rem-calc(12);
  }

  .crumbCurrent {
    color: rgba($black, 0.87);
    font-size: rem-calc(12);
  }

  .link {
    color: $primary;
    cursor: pointer;
    display: block;
    font-size: rem-calc(12);

    html[data-theme="admin"] & {
      color: $primary-admin;
    }
  }

  @media print {
    display: none;
  }
}


