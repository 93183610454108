@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?4w2htk');
  src:  url('fonts/icomoon.eot?4w2htk#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?4w2htk') format('truetype'),
    url('fonts/icomoon.woff?4w2htk') format('woff'),
    url('fonts/icomoon.svg?4w2htk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icons8-high_priority1:before {
  content: "\e990";
}
.icon-icons8-error-filled1:before {
  content: "\e992";
}
.icon-icons8-iphone1:before {
  content: "\e993";
}
.icon-icons8-laptop1:before {
  content: "\e994";
}
.icon-icons8-lock11:before {
  content: "\e995";
}
.icon-icons8-true_false1:before {
  content: "\e996";
}
.icon-api:before {
  content: "\e96b";
}
.icon-import_csv:before {
  content: "\e96c";
}
.icon-justify_left:before {
  content: "\e96d";
}
.icon-icons8-export_pdf:before {
  content: "\e965";
}
.icon-icons8-high_priority:before {
  content: "\e963";
}
.icon-icons8-error-filled:before {
  content: "\e964";
}
.icon-icons8-minus_sign:before {
  content: "\e961";
}
.icon-icons8-content:before {
  content: "\e960";
}
.icon-icons8-add_user_male:before {
  content: "\e927";
}
.icon-icons8-add:before {
  content: "\e928";
}
.icon-icons8-clapperboard:before {
  content: "\e949";
}
.icon-icons8-defense:before {
  content: "\e94a";
}
.icon-icons8-development_skill:before {
  content: "\e94b";
}
.icon-icons8-drag_reorder:before {
  content: "\e94c";
}
.icon-icons8-dropdown_field:before {
  content: "\e94d";
}
.icon-icons8-edit_row:before {
  content: "\e94e";
}
.icon-icons8-globe:before {
  content: "\e94f";
}
.icon-icons8-help1:before {
  content: "\e950";
}
.icon-icons8-horizontal_line:before {
  content: "\e951";
}
.icon-icons8-ipad:before {
  content: "\e952";
}
.icon-icons8-iphone:before {
  content: "\e953";
}
.icon-icons8-laptop:before {
  content: "\e954";
}
.icon-icons8-lock1:before {
  content: "\e955";
}
.icon-icons8-map:before {
  content: "\e956";
}
.icon-icons8-paragraph:before {
  content: "\e957";
}
.icon-icons8-pencil1:before {
  content: "\e958";
}
.icon-icons8-radio_button:before {
  content: "\e959";
}
.icon-icons8-search1:before {
  content: "\e95a";
}
.icon-icons8-shared_document:before {
  content: "\e95b";
}
.icon-icons8-sort_up:before {
  content: "\e95c";
}
.icon-icons8-tags:before {
  content: "\e95d";
}
.icon-icons8-todo_list:before {
  content: "\e95e";
}
.icon-icons8-true_false:before {
  content: "\e95f";
}
.icon-icons8-align_justify:before {
  content: "\e945";
}
.icon-icons8-table:before {
  content: "\e946";
}
.icon-icons8-delete_link:before {
  content: "\e944";
}
.icon-icons8-indent:before {
  content: "\e93f";
}
.icon-icons8-link:before {
  content: "\e940";
}
.icon-icons8-list:before {
  content: "\e941";
}
.icon-icons8-numbered_list:before {
  content: "\e942";
}
.icon-icons8-outdent:before {
  content: "\e943";
}
.icon-icons8-pdf:before {
  content: "\e93b";
}
.icon-icons8-messaging:before {
  content: "\e939";
}
.icon-icons8-send_comment:before {
  content: "\e937";
}
.icon-icons8-pencil:before {
  content: "\e933";
}
.icon-icons8-flag:before {
  content: "\e932";
}
.icon-icons8-lock:before {
  content: "\e938";
}
.icon-icons8-star:before {
  content: "\e935";
}
.icon-icons8-star-filled:before {
  content: "\e936";
}
.icon-icons8-no-synchronize:before {
  content: "\e930";
}
.icon-icons8-synchronize:before {
  content: "\e931";
}
.icon-icons8-phonelink_setup:before {
  content: "\e934";
}
.icon-icons8-cancel:before {
  content: "\e92e";
}
.icon-icons8-checked:before {
  content: "\e92f";
}
.icon-icons8-user_male_circle:before {
  content: "\e92d";
}
.icon-icons8-error:before {
  content: "\e92c";
}
.icon-icons8-comments:before {
  content: "\e926";
}
.icon-name_picker:before {
  content: "\e91a";
}
.icon-more_vert:before {
  content: "\e967";
}
.icon-send:before {
  content: "\e9d7";
}
.icon-cheveron-up:before {
  content: "\e99e";
}
.icon-cheveron-down:before {
  content: "\e99f";
}
.icon-icons8-help:before {
  content: "\e93a";
}
.icon-weather-error .path1:before {
  content: "\e9d1";
  color: rgb(233, 233, 233);
}
.icon-weather-error .path2:before {
  content: "\e9d2";
  margin-left: -1.5830078125em;
  color: rgb(194, 194, 194);
}
.icon-weather-error .path3:before {
  content: "\e9d3";
  margin-left: -1.5830078125em;
  color: rgb(194, 194, 194);
}
.icon-weather-error .path4:before {
  content: "\e9d4";
  margin-left: -1.5830078125em;
  color: rgb(194, 194, 194);
}
.icon-weather-clear-night:before {
  content: "\e9a3";
  color: #f9ac19;
}
.icon-weather-cloudy-day:before {
  content: "\e9a4";
  color: #bababa;
}
.icon-weather-freezing-rain-day .path1:before {
  content: "\e9a5";
  color: rgb(186, 186, 186);
}
.icon-weather-freezing-rain-day .path2:before {
  content: "\e9a6";
  margin-left: -1.111328125em;
  color: rgb(150, 210, 255);
}
.icon-weather-freezing-rain-day .path3:before {
  content: "\e9a7";
  margin-left: -1.111328125em;
  color: rgb(150, 210, 255);
}
.icon-weather-freezing-rain-day .path4:before {
  content: "\e9a8";
  margin-left: -1.111328125em;
  color: rgb(150, 210, 255);
}
.icon-weather-freezing-rain-day .path5:before {
  content: "\e9ac";
  margin-left: -1.111328125em;
  color: rgb(150, 210, 255);
}
.icon-weather-partly-cloudy-day .path1:before {
  content: "\e9ad";
  color: rgb(249, 172, 25);
}
.icon-weather-partly-cloudy-day .path2:before {
  content: "\e9ae";
  margin-left: -1.111328125em;
  color: rgb(249, 172, 25);
}
.icon-weather-partly-cloudy-day .path3:before {
  content: "\e9b1";
  margin-left: -1.111328125em;
  color: rgb(249, 172, 25);
}
.icon-weather-partly-cloudy-day .path4:before {
  content: "\e9b2";
  margin-left: -1.111328125em;
  color: rgb(249, 172, 25);
}
.icon-weather-partly-cloudy-day .path5:before {
  content: "\e9b3";
  margin-left: -1.111328125em;
  color: rgb(249, 172, 25);
}
.icon-weather-partly-cloudy-day .path6:before {
  content: "\e9b4";
  margin-left: -1.111328125em;
  color: rgb(249, 172, 25);
}
.icon-weather-partly-cloudy-day .path7:before {
  content: "\e9b5";
  margin-left: -1.111328125em;
  color: rgb(249, 172, 25);
}
.icon-weather-partly-cloudy-day .path8:before {
  content: "\e9b8";
  margin-left: -1.111328125em;
  color: rgb(186, 186, 186);
}
.icon-weather-partly-cloudy-night .path1:before {
  content: "\e9b9";
  color: rgb(186, 186, 186);
}
.icon-weather-partly-cloudy-night .path2:before {
  content: "\e9ba";
  margin-left: -1.111328125em;
  color: rgb(249, 172, 25);
}
.icon-weather-rain-day .path1:before {
  content: "\e9bb";
  color: rgb(186, 186, 186);
}
.icon-weather-rain-day .path2:before {
  content: "\e9bc";
  margin-left: -1.111328125em;
  color: rgb(1, 114, 206);
}
.icon-weather-scattered-showers-day .path1:before {
  content: "\e9bd";
  color: rgb(249, 172, 25);
}
.icon-weather-scattered-showers-day .path2:before {
  content: "\e9be";
  margin-left: -1.111328125em;
  color: rgb(186, 186, 186);
}
.icon-weather-scattered-showers-day .path3:before {
  content: "\e9bf";
  margin-left: -1.111328125em;
  color: rgb(1, 114, 206);
}
.icon-weather-scattered-showers-night .path1:before {
  content: "\e9c0";
  color: rgb(186, 186, 186);
}
.icon-weather-scattered-showers-night .path2:before {
  content: "\e9c1";
  margin-left: -1.111328125em;
  color: rgb(249, 172, 25);
}
.icon-weather-scattered-showers-night .path3:before {
  content: "\e9c2";
  margin-left: -1.111328125em;
  color: rgb(1, 114, 206);
}
.icon-weather-snow-day .path1:before {
  content: "\e9c3";
  color: rgb(186, 186, 186);
}
.icon-weather-snow-day .path2:before {
  content: "\e9c4";
  margin-left: -1.111328125em;
  color: rgb(150, 210, 255);
}
.icon-weather-sunny-day:before {
  content: "\e9c5";
  color: #f9ac19;
}
.icon-weather-thunderstorms-day .path1:before {
  content: "\e9c6";
  color: rgb(186, 186, 186);
}
.icon-weather-thunderstorms-day .path2:before {
  content: "\e9c7";
  margin-left: -1.111328125em;
  color: rgb(249, 172, 25);
}
.icon-weather-thunderstorms-day .path3:before {
  content: "\e9c8";
  margin-left: -1.111328125em;
  color: rgb(1, 114, 206);
}
.icon-weather-windy-and-rainy-day .path1:before {
  content: "\e9c9";
  color: rgb(150, 210, 255);
}
.icon-weather-windy-and-rainy-day .path2:before {
  content: "\e9ca";
  margin-left: -1.111328125em;
  color: rgb(1, 114, 206);
}
.icon-weather-windy-and-rainy-day .path3:before {
  content: "\e9cb";
  margin-left: -1.111328125em;
  color: rgb(186, 186, 186);
}
.icon-weather-windy-day:before {
  content: "\e9cd";
  color: #96d2ff;
}
.icon-weather-wintry-weather-day .path1:before {
  content: "\e9ce";
  color: rgb(150, 210, 255);
}
.icon-weather-wintry-weather-day .path2:before {
  content: "\e9cf";
  margin-left: -1.111328125em;
  color: rgb(1, 114, 206);
}
.icon-weather-wintry-weather-day .path3:before {
  content: "\e9d0";
  margin-left: -1.111328125em;
  color: rgb(186, 186, 186);
}
.icon-substation:before {
  content: "\e9a1";
}
.icon-photo-icons:before {
  content: "\e9a0";
}
.icon-download:before {
  content: "\e997";
}
.icon-circle_check:before {
  content: "\e99a";
}
.icon-fallen-tree:before {
  content: "\e96e";
}
.icon-icons8-power-box:before {
  content: "\e96f";
}
.icon-icons8-power-pole-large:before {
  content: "\e970";
}
.icon-icons8-power-pole:before {
  content: "\e971";
}
.icon-icons8-severed-line:before {
  content: "\e972";
}
.icon-power-cord:before {
  content: "\e9b7";
}
.icon-icons8-sun1:before {
  content: "\e973";
}
.icon-icons8-moon_symbol1:before {
  content: "\e974";
}
.icon-icons8-lightning_bolt1:before {
  content: "\e975";
}
.icon-onedrive:before {
  content: "\eaaf";
}
.icon-droplet:before {
  content: "\e976";
}
.icon-icons8-snow:before {
  content: "\e977";
}
.icon-icons8-wind:before {
  content: "\e978";
}
.icon-icons8-hurricane-winds:before {
  content: "\e979";
}
.icon-lab:before {
  content: "\e9aa";
}
.icon-fire:before {
  content: "\e9a9";
}
.icon-magnet:before {
  content: "\e9ab";
}
.icon-bug:before {
  content: "\e999";
}
.icon-switch:before {
  content: "\e9b6";
}
.icon-wrench:before {
  content: "\e991";
}
.icon-icons8-settings1:before {
  content: "\e97a";
}
.icon-airplane:before {
  content: "\e9af";
}
.icon-icons8-a_home1:before {
  content: "\e97b";
}
.icon-office:before {
  content: "\e97c";
}
.icon-truck:before {
  content: "\e9b0";
}
.icon-binoculars:before {
  content: "\e985";
}
.icon-user:before {
  content: "\e97d";
}
.icon-users:before {
  content: "\e97e";
}
.icon-icons8-business_conference_female_speaker1:before {
  content: "\e97f";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-lifebuoy:before {
  content: "\e980";
}
.icon-aid-kit:before {
  content: "\e998";
}
.icon-icons8-under_construction1:before {
  content: "\e981";
}
.icon-flag:before {
  content: "\e9cc";
}
.icon-bullhorn:before {
  content: "\e982";
}
.icon-bell:before {
  content: "\e983";
}
.icon-address-book:before {
  content: "\e984";
}
.icon-book:before {
  content: "\e986";
}
.icon-icons8-add_file1:before {
  content: "\e987";
}
.icon-folder:before {
  content: "\e988";
}
.icon-calendar:before {
  content: "\e989";
}
.icon-icons8-autograph1:before {
  content: "\e98a";
}
.icon-icons8-marker1:before {
  content: "\e98b";
}
.icon-map:before {
  content: "\e98c";
}
.icon-compass2:before {
  content: "\e98d";
}
.icon-stats-dots:before {
  content: "\e99b";
}
.icon-connection:before {
  content: "\e98e";
}
.icon-stopwatch:before {
  content: "\e98f";
}
.icon-icons8-edit:before {
  content: "\e969";
}
.icon-trash:before {
  content: "\e96a";
}
.icon-icons8-clock:before {
  content: "\e966";
}
.icon-share:before {
  content: "\e962";
}
.icon-icons8-pencil_fill:before {
  content: "\e93d";
  color: #666;
}
.icon-icons8-phone_settings:before {
  content: "\e93c";
  color: #666;
}
.icon-icons8-info:before {
  content: "\e929";
}
.icon-icons8-delete_trash:before {
  content: "\e92a";
  color: #fff;
}
.icon-icons8-email:before {
  content: "\e92b";
  color: #fff;
}
.icon-icons8-documents:before {
  content: "\e93e";
  color: #fff;
}
.icon-icons8-calendar:before {
  content: "\e947";
}
.icon-icons8-export_csv:before {
  content: "\e91e";
}
.icon-icons8-multi_edit:before {
  content: "\e91f";
}
.icon-icons8-urgent_message:before {
  content: "\e920";
}
.icon-icons8-business_conference_female_speaker:before {
  content: "\e921";
}
.icon-icons8-xls_export:before {
  content: "\e922";
}
.icon-icons8-a_home:before {
  content: "\e923";
}
.icon-icons8-print:before {
  content: "\e924";
}
.icon-icons8-overview_pages_4:before {
  content: "\e925";
}
.icon-icons8-linked_file:before {
  content: "\e91d";
}
.icon-icons8-document-2:before {
  content: "\e91c";
}
.icon-icons8-person_male:before {
  content: "\e91b";
}
.icon-icons8-chevron-left:before {
  content: "\e919";
}
.icon-icons8-chevron-right:before {
  content: "\e948";
}
.icon-icons8-add_file:before {
  content: "\e918";
}
.icon-icons8-administrator_male:before {
  content: "\e900";
}
.icon-icons8-autograph:before {
  content: "\e901";
}
.icon-icons8-checkmark:before {
  content: "\e902";
}
.icon-icons8-combo_chart:before {
  content: "\e903";
}
.icon-icons8-data_configuration:before {
  content: "\e904";
}
.icon-icons8-delete_sign:before {
  content: "\e905";
}
.icon-icons8-document:before {
  content: "\e906";
}
.icon-icons8-expand_arrow:before {
  content: "\e907";
}
.icon-icons8-extra_features:before {
  content: "\e908";
}
.icon-icons8-lightning_bolt:before {
  content: "\e909";
}
.icon-icons8-mail_filter:before {
  content: "\e90a";
}
.icon-icons8-marker:before {
  content: "\e90b";
}
.icon-icons8-menu:before {
  content: "\e90c";
}
.icon-icons8-microphone:before {
  content: "\e90d";
}
.icon-icons8-minus2:before {
  content: "\e90e";
}
.icon-icons8-moon_symbol:before {
  content: "\e90f";
}
.icon-icons8-more:before {
  content: "\e910";
}
.icon-icons8-place_marker:before {
  content: "\e911";
}
.icon-icons8-plus_math:before {
  content: "\e912";
}
.icon-icons8-search:before {
  content: "\e913";
}
.icon-icons8-sort_down:before {
  content: "\e914";
}
.icon-icons8-sun:before {
  content: "\e915";
}
.icon-icons8-under_construction:before {
  content: "\e916";
}
.icon-icons8-user_female_circle:before {
  content: "\e917";
}
.icon-icons8-settings:before {
  content: "\e968";
}
.icon-images:before {
  content: "\e99c";
}
.icon-camera:before {
  content: "\e99d";
}
.icon-undo2:before {
  content: "\e9a2";
}
.icon-tab:before {
  content: "\ea45";
}
.icon-magic-wand:before {
  content: "\e9d5";
}
.icon-bubble2:before {
  content: "\e9d6";
}
.icon-ai-bot:before {
  content: "\e9d8";
}
.icon-hazard-icon:before {
  content: "\e9d9";
  color: #e3590a;
}
.icon-copy-text:before {
  content: "\e9da";
}
