@import "../../sass/abstracts/variables";

.toastWrapper {
  display: flex;
  justify-content: space-between;
}

.toastMessage {
  font-family: "Roboto";
  font-weight: $font-weight-regular;
  font-size: 16px;
  line-height: 24px;
}

.toastStatus {
  font-family: "Roboto";
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .1rem;
  padding-left: 16px;
}

.error {
  &Background {
    background-color: $error-fill;
    border: 1px solid $error;
    border-radius: 4px;
    box-shadow: none;
    min-height: 51px;
    padding: 13px 16px;
  }
  
  &Body {
    color: $error;
  }

  &Body :global(.Toastify__toast-icon) {
    margin-inline-end: 16px;
    padding: 0;
  }
}

.info {
  &Background {
    background-color: $active-fill;
    border: 1px solid $primary;
    border-radius: 4px;
    box-shadow: none;
    min-height: 51px;
    padding: 13px 16px;

    html[data-theme="admin"] & {
      border-color: $primary-admin;
    }
  }
  
  &Body {
    color: $primary;
    padding: 0;

    html[data-theme="admin"] & {
      color: $primary-admin;
    }
  }

  &Body :global(.Toastify__toast-icon) {
    margin-inline-end: 16px;
  }
}

.success {
  &Background {
    background-color: $success-fill;
    border: 1px solid $success;
    border-radius: 4px;
    box-shadow: none;
    min-height: 51px;
    padding: 13px 16px;
  }
  
  &Body {
    color: $success;
    padding: 0;
  }

  &Body :global(.Toastify__toast-icon) {
    margin-inline-end: 16px;
  }
}

.warning {
  &Background {
    background-color: $warning-fill;
    border: 1px solid $warning;
    border-radius: 4px;
    box-shadow: none;
    min-height: 51px;
    padding: 13px 16px;
  }
  
  &Body {
    color: $warning;
    padding: 0;
  }

  &Body :global(.Toastify__toast-icon) {
    margin-inline-end: 16px;
  } 
}