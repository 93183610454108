@import "../../../sass/abstracts/variables";
@import "../../../sass/abstracts/functions";

.buttonRow {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.label {
  color: $white;
  font-size: rem-calc(14);
}

.label_cancel {
  color: $error;
  cursor: pointer;
}

.cancelButton {
  background: none;
  border-radius: 4px;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  height: rem-calc(48);
  width: rem-calc(170);
  /* Matching value from src/components/forms/Button */
  // display: flex;
  // align-items: center;

  &:hover {
    cursor: pointer;
    background-color: $active-fill;
  }
}

.saveButton {
  height: rem-calc(48);
  width: rem-calc(170);
  justify-content: center;

  &:hover {
    background-color: $secondary;
    cursor: pointer;

    html[data-theme="admin"] & {
      background-color: $secondary-admin;
    }
  }
}
