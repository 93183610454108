@import "../../../sass/abstracts/mixins";

.navigationItem {
  @include focus-indicator-appear-inset;
  @include focus-indicator-disappear-inset;

  border-radius: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 40px;
  margin: 10px 20px 0 20px;
  padding: 20px 25px;

  &.active {
    background-color: $primary;
    color: $white;

    html[data-theme="admin"] & {
      color: $white;
      background-color: $primary-admin;
    }

    &:active,
    &:visited {
      color: $white;

      html[data-theme="admin"] & {
        color: $white;
      }
    }

    &:focus-visible,
    &:hover {
      background-color: $secondary;

      html[data-theme="admin"] & {
        background-color: $secondary-admin;
      }
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }

  &.inactive {
    color: $primary;

    html[data-theme="admin"] & {
      color: $primary-admin;
    }

    &:focus-visible,
    &:hover {
      background-color: $light-white;

      html[data-theme="admin"] & {
        background-color: $light-white;
      }
    }
  }

  @media screen and (min-width: $breakpoint-extra-large) {
    border-radius: 0;
    margin: 0;
    padding: 15px 25px;
  }
}

.navigationListButton {
  background-color: transparent;
  border: 0;
}

.navigationListLink {
  @include reset-a;

  &:active,
  &:visited {
    color: $primary;
    
    html[data-theme="admin"] & {
      color: $primary-admin;
    }
  }
}

.subNavigationItem {
  color: $primary;
  padding-left: 50px;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }
}

.navigationItemIcon {
  font-size: 1.25rem !important;
  padding-right: 15px;
}

.navigationItemLink {
  font-size: 0.813rem !important; // 13px
}
