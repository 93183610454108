@import "shared/src/sass/abstracts/variables";

.documentQuestionContainer {
  background-color: $snow;
  display: flex;
  width: 100%;
  height: calc(100vh - 64px);
  padding: 0px 33px 33px 33px;
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
// I need to figure out why this doesn't scroll up, but this fills the background to the scroll bottom -AE
//   overflow: auto; 

  .mainContainer {
    width: 100%;

    .documentTitle {
      display: flex;
      font-size: 30px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: -0.5px;
      justify-content: space-between;

      span:first-child {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .moreVert {
        cursor: pointer;
      }
    }

    .sectionContainer {
      position: relative;
      display: flex;
      padding: 1px 20px 20px 20px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 5px;
      border: 1px solid $light-grey;
      background: $white;
      align-items: flex-start;
      margin-bottom: 33px;

      .statusTabWrapper {
        position: absolute;
        right: -50px;
        cursor: default;
      }

      .statusTabWrapper:first-child {
        top: 0;
      }
      .statusTabWrapper:nth-child(2) {
        top: 105px;
      }
      .statusTabInfo {
        display: none;
        position: absolute;
        top: 27px;
        right: 30px;
        width: 400px;
        border: 1px solid;
        border-right: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 10px;
      }

      

      .statusTabWrapper:hover .statusTabInfo {
        display: inline-block;
      }

      .statusTab {
        border-style: solid;
        border-width: 1px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        display: block;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 1.88px;
        line-height: 1.16667;
        margin: 27px 0 0 -1px;
        padding: 13px 7px;
        position: sticky;
        text-transform: uppercase;
        top: 0;
        width: 30px;
        writing-mode: vertical-lr;
      }

      .statusTab:hover+.statusTabInfo {
        display: none;
      }

      &.documentSaved {
        border: 1px solid $warning;

        .statusTabWrapper.savedTab {
          .statusTabInfo {
            background-color: $warning-fill;
            border-color: $warning;
            color: $warning
          }
          .statusTab {
            background-color: $warning-fill;
            border-color: $warning;
            color: $warning
          }
        }
      }

      &.documentSubmitted {
        border: 1px solid $success;
        .statusTabWrapper.submittedTab {
          .statusTabInfo {
            background-color: $success-fill;
            border-color: $success;
            color: $success
          }
        
          .statusTab {
            background-color: $success-fill;
            border-color: $success;
            color: $success
          }
        }
      }

      &.documentRehuddle {
        border: 1px solid $primary;

        html[data-theme="admin"] & {
          border-color: $primary-admin;
        }
        .statusTabWrapper.rehuddleTab {
          .statusTabInfo {
            background-color: $active-fill;
            border-color: $primary;
            color: $primary;

            html[data-theme="admin"] & {
              border-color: $primary-admin;
              color: $primary-admin;
            }
          }
        
          .statusTab {
            background-color: $active-fill;
            border-color: $primary;
            color: $primary;

            html[data-theme="admin"] & {
              border-color: $primary-admin;
              color: $primary-admin;
            }
          }
        }
      }
      
      &.documentErrors {
        border: 1px solid $error;
        .statusTabWrapper.errorsTab {
          .statusTabInfo {
            background-color: $error-fill;
            border-color: $error;
            color: $error
          }
        
          .statusTab {
            background-color: $error-fill;
            border-color: $error;
            color: $error
          }
        }
      }

      .progressHeader {
        display: flex;
        justify-content: space-between;
        padding: 20px 0px 20px 6px;
        align-items: center;
        align-self: stretch;
        border-bottom: 1px solid $light-grey-blue;
        background-color: $white;
        margin-bottom: 10px;
        position: sticky;
        top: 1px;
        z-index: 499;

        .headerContainer {
          width: 100%;
        }

        .sectionDesciptionContainer {
          margin: 5px 0 0 80px;
          height: 58px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          
          .sectionTitle {
            color: $dark-grey;
            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 3px;
            text-transform: uppercase;
            margin: 10px 0 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          
          .upNextSectionTitle {
            color: $eerie-black;
            font-size: 13px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: normal;
            text-transform: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-top: 5px;
            
            span:first-child {
              font-weight: 700;
            }

            span.upNextLink {
              color: $primary;
              cursor: pointer;

              html[data-theme="admin"] & {
                color: $primary-admin;
              }
            }
          }
        }

        .documentStatus {
          display: flex;
          flex-direction: column;
          white-space: nowrap;
          font-size: 13px;
          line-height: 15px;
          padding-top: 10px;

          .lastSavedTimestamp {
            display: flex;
            flex-direction: row;
            padding-left: 5px;

            .lastSaved {
              color: $success;
              font-weight: 700;
              padding-right: 4px;
            }

            .timestamp {
              color: $dark-grey;
            }
          }

          .errors {
            padding-left: 4px;
            color: $error;
          }
        }
      }

      @media screen and (max-width: 600px) {
        .progressHeader {
          .sectionDesciptionContainer {
            flex-direction: column;

            .upNextSectionTitle {
              display: none;
            }
          

            .documentStatus {
              padding-top: 0;
              position: relative;
              top: -10px;

              .lastSavedTimestamp {
                flex-direction: row;
                padding-left: 0;

                .lastSaved {
                  padding-right: 4px;
                  display: flex;
                  justify-content: flex-end;
                }
              }
              
              .errors {
                display: none;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: $breakpoint-large) {
      .sectionContainer {
        margin-bottom: 12px;

        .progressHeader {
          top: 0;

          .documentStatus {
            .lastSavedTimestamp {
              flex-direction: column;

              .lastSaved {
                padding-right: 0;
                display: flex;
                justify-content: flex-end;
              }
            }

            .errors {
              display: flex;
               justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}

