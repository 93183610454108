@import "../../../sass/abstracts/mixins";

.container {
  display: flex;
  flex-direction: column;
  padding: 18px 24px;
  margin-left: 24px;
  width: 126px;
  background-color: $white;
  border-radius: 5px;
  border: 1px solid $grey-100;
  align-items: center;
  justify-content: center;

  @media (min-width: $max-phone) {
    margin-left: 34px;
  }
}

.documentImage {
  color: $white;
  font-size: 3em;
}

.createText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  margin-top: 8px;
  color: $primary;
  font-size: rem-calc(12);
  font-weight: 500;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }
}

.startButton {
  background-color: $light-blue;
  border-radius: 4px;
  color: $primary;
  height: 34px;
  width: 34px;
  padding: 12px 8px 8px 10px;
  margin-top: 8px;
  text-align: center;

  html[data-theme="admin"] & {
    background-color: $primary-fill-admin;
    color: $primary-admin;
  }
  
  &:hover {
    background-color: $primary;
    color: $primary-fill;

    html[data-theme="admin"] & {
      background-color: $primary-admin;
      color: $primary-fill-admin
    }
  }
}
