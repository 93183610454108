.photoQuestionWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
//   margin: 6px 0;
}

.question {
  display: flex;
  width: 100%;

  > div {
    width: 100%;
  }
}

.selectionPhotoWrapper {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  width: 100%;
}
