@import "shared/src/sass/abstracts/variables";

.helpDesk {
  max-width: 990px;
  padding: 0 15px;
  width: 100%;
}

.helpDeskTitle {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.26px;
  padding-top: 46.5px;
}

.about {
  color: $black;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.49px;
  margin-top: 34px;
}

.helpDeskRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 13px;
}

.helpDeskMenuItem {
  cursor: pointer;
}

.chevronLink {
  color: $dark-grey;
  cursor: pointer;
  font-size: 1.5rem;
  border: none;
  background: none;
}

.gotQuestionsWrapper {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 3px;
  margin-top: 48px;
}

.gotQuestionsIcon {
  color: $primary;
  font-size: 1.875rem;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }
}

.gotQuestionsText {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.28px;
  text-decoration: underline;
}

.gotQuestionsCTA {
  display: flex;
  flex-direction: column;
  margin-left: 13px;
}

.gotQuestionsHeading {
  color: $dark-grey;
  text-transform: uppercase;
  letter-spacing: 2.19px;
  font-weight: 500;
  font-size: 0.865rem;
}

.rowTitle {
  color: $black;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.29px;
}

.versionNumber {
  color: $dark-grey;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.29px;
}
