@import "shared/src/sass/abstracts/mixins";

.bottomNavigation {
  bottom: 0px;
  position: fixed;
  width: 100%;
  box-shadow: 0px 1px 3px rgba($black, 0.2);
  left: 0px;
  z-index: 1000;

  .bottomNavigationBar {
    background-color: $primary;
    color: $primary-fill;

    html[data-theme="admin"] & {
      background-color: $primary-admin;
      color: $primary-fill-admin;
    }

    .basicAction {
      color: $primary-fill;

      html[data-theme="admin"] & {
        color: $primary-fill-admin;
      }

      &.active {
        border-top: 5px solid $primary-fill;
        padding-bottom: 5px;

        html[data-theme="admin"] & {
          border-top: 5px solid $primary-fill-admin;
        }
      }
    }
  }
}

.icon {
  font-size: 1.5rem;
}

.activeTab {
  color: $primary !important;
  font-weight: 500;

  html[data-theme="admin"] & {
    color: $primary-admin !important;
  }
}
