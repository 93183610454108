@import "../../../../../shared/src/sass/abstracts/mixins";

.documentHistory {
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 14px;
  text-align: left;
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
}

.shareDocumentWrapper {
  margin-top: 16px;
  text-align: left;
}

.version {
  align-items: flex-end;
  color: $medium-grey;
  display: flex;
  font-size: 0.75rem;
  margin-bottom: 2px;
  margin-left: 5px;
}
