@import "../../../sass/abstracts/variables";

.tab {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 8rem;
  min-height: 4.3rem;
  overflow: hidden;
  padding-right: 1rem;

  @media print {
    display: none;
  }
}

.tabList {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 2px solid $light-grey;
  background-color: $primary;

  html[data-theme="admin"] & {
    background-color: $primary-admin;
  }
}

.whiteTabList {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 2px solid $light-grey;
}

.noUnderline {
  border-bottom: none;
}
