@import "../../../../sass/abstracts/mixins";

.menuList {
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
}
