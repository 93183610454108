@import "../../sass/abstracts/mixins";

.selectLabel {
  color: $black;
  margin-bottom: 8px;
  font-size: 1.25rem;
  font-weight: 400;
}

.select {
  padding-left: 0 !important;
  height: auto !important;
  color: $dark-grey-blue !important;
  min-width: 165px;

  &:focus {
    background-color: $white;
  }

  & span {
    max-width: 100%;
  }

  :global(.react-select__single-value) {
    color: unset;
  }
  :global(.react-select__control) {
    border: 1px solid $very-light-grey;
    border-radius: 5px;
  }
  :global(.react-select__value-container) {
    padding: 0.25rem;
  }
  :global(.react-select__indicator-separator) {
    display: none;
  }
}

.menuPaper {
  max-height: 18.5rem;
  max-width: 180px !important;
}
