@import "../../../sass/abstracts/mixins";

.header {
  align-items: center;
  background: $white;
  border-bottom: 1px solid $grey-100;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: rem-calc(4) 18px;
  position: fixed;
  right: 0;
  z-index: 11;
}
