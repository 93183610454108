@import "../../../sass/abstracts/mixins";

.initials {
  align-items: center;
  border: 1px solid $grey-100;
  border-radius: 50px;
  color: $dark-grey;
  display: flex;
  font-size: rem-calc(20);
  font-weight: 900;
  height: 50px;
  justify-content: center;
  letter-spacing: -1px;
  line-height: 1.375;
  text-transform: capitalize;
  width: 50px;
}

.logOutButton {
  @include focus-indicator-appear;
  @include focus-indicator-disappear;
  @include reset-button;

  align-items: center;
  border-radius: 4px;
  color: $red-700;
  display: flex;
  flex-direction: row;
  font-size: rem-calc(13);
  justify-content: space-between;
  line-height: 1.1538;
  margin: 8px;
  padding: 8px 0 8px 8px;
  text-transform: capitalize;

  &:hover {
    background-color: $light-white;
  }
}

.menuButton {
  @include focus-indicator-appear;
  @include focus-indicator-disappear;
  @include reset-button;

  align-items: center;
  border-radius: 4px;
  display: flex;
  padding: 3px 4px;

  &:hover {
    background-color: $light-white;
  }
}

.popper {
  z-index: 12000;

  &Content {
    display: flex;
    flex-direction: column;

    &:focus-visible {
      outline: none;
    }
  }
}
