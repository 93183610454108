.relatedDocuments {
  table {
    table-layout: fixed;

    td, th {
      &:first-child {
        width: 41.666667%;
      }
      &:last-child {
        width: 20%;
      }
    }
  }

  .overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}