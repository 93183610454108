@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

$sidebar-width: rem-calc(378);

// Global sidebar
.sidebar {
  overflow-y: scroll;
  overflow: initial;
  width: $sidebar-width;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 0 1px;
  background-color: $white;
  border-color: $medium-grey;
  color: $dark-grey;
}

.sidebarContainer {
  width: $sidebar-width;
  height: 100%;
  display: flex;
}

.sidebarIntro {
  font-size: 0.75rem;
  padding: 1rem;
  position: relative;
  background: $white;
  margin-bottom: 10px;
}

.introLeft {
  border-radius: 0 4px 4px 0;
  border-left: 0;
}

.introRight {
  left: -0.75rem;
  border-radius: 4px 0 0 4px;
  border-right: 0;
}

.introActive {
  width: calc(100% + 0.75rem);
  padding: calc(1rem - 2px) 1rem; // prevent size changes from added border
  background: $active-fill;
  border-color: $primary;
  border-width: 2px;
  border-style: solid;

  html[data-theme="admin"] & {
    background: $primary-fill-admin;
    border-color: $primary-admin;
  }
}

.sidebarIntroLabel {
  color: black;
  font-size: 0.75rem;
  letter-spacing: 1.88pt;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 0 rem-calc(8) 0;
}

.label {
  color: $black;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: rem-calc(0.15);
}

.section {
  padding: 1rem 0;
  border-bottom: 1px solid $light-grey
}
