.active {
  color: #00853c;
}

.deactivated {
  color: #f91919;
}

.nonActivated {
  color: #f9ac19;
}
