@import "../../../../sass/abstracts/functions";
@import "../../../../sass/abstracts/variables";

.container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
}

.error {
  border: 2px solid $error;
  padding: rem-calc(16);
  border-radius: rem-calc(4);
  background: $error-fill;
}

.commentField {
  margin-top: 0.375rem;
}

.fieldWrapper {
    display: flex;
    flex-direction: column;

    .bottomRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}