@import "./../../sass/abstracts/variables";
@import "./../../sass/abstracts/functions";
@import "./../../sass/abstracts/mixins";

.link {
  @include focus-indicator-appear;
  @include focus-indicator-disappear;

  border-radius: 4px;
  color: $primary;
  text-decoration: underline;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }

  &:hover {
    color: $secondary;

    html[data-theme="admin"] & {
      color: $secondary-admin;
    }
  }

  &:visited {
    color: $visited;
  }
}

.linkDisabled {
  color: $dark-grey-blue !important;
}
