@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";

.actionButton {
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.5rem;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: $light-grey;
  }

  &:focus-within {
    box-shadow: 0 0 0 rem-calc(4) $focus-indicator-outline;
    background-color: $light-grey;
  }
}

.actionButtonWrapper {
    margin-right: 15px;

  button {
    width: 24px;
    height: 24px;
  }
}

.moreVertIcon {
  color: $black;
}

.toolTip {
  background-color: $black;
  color: $white;
  border-color: $black;
  font-size: rem-calc(16);
  font-weight: normal;
  line-height: rem-calc(24);
}

.arrow {
  color: $black;
}
