@import "../../../../sass/abstracts/mixins";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: rem-calc(20);
  }
}

.closeBtn {
  background: none;
  border: none;
  padding-bottom: rem-calc(10);

  @media (max-width: $max-tablet) {
    display: none;
  }
}

.setFormValue {
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }
}

.workOrderWrapper {
  margin: 0.5rem 0;
  overflow: scroll;
  max-height: 385px;

  @media (max-width: $max-tablet) {
    overflow: scroll;
    max-height: 250px;
    margin-bottom: 10px;
  }
}

.workOrderSearch {
  margin-top: 0.5rem;
  border: 1px solid $medium-grey !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  height: auto;
  border-radius: 1.5rem !important;
}

.workOrderItem {
  flex-direction: column;
  border-color: $medium-grey;

  .details {
    margin-top: 0.5rem;
    font-size: rem-calc(14);
    color: $dark-grey;
    justify-content: space-between;
  }

  .overflow {
    flex-grow: 0;
    flex-basis: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.formItem {
  justify-content: space-between;
  cursor: pointer;

  .formTypeIcon {
    background: none;
    padding: none;
    margin-right: 0.5rem;
    font-size: rem-calc(20);
  }
}

.alignCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.disabledForm {
  margin: 0;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  font-style: italic;
}

.formActions {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  button {
    font-size: rem-calc(14);
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .secondaryBtn {
    border-color: $primary;

    html[data-theme="admin"] & {
      border-color: $primary-admin;
    }
  }
}
