@import "shared/src/sass/abstracts/mixins";

.myDocuments {
  @media (max-width: $max-tablet) {
    padding-bottom: 150px;
  }

  .documentsListContainer {
    margin-top: 15px;
    border: 1px solid $very-light-grey;
    background-color: $white;
    border-radius: 5px;
    
    @media screen and (max-width: $max-phone) {
      border-radius: 0;
      border-left: none;
      border-right: none;
    }
  }

}
