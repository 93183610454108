@import 'variables-user';
@import 'variables-admin';

$black: rgb(24, 24, 24); // #222222
$eerie-black: rgb(31, 31, 31); //#1F1F1F
$box-shadow: rgba(0, 0, 0, 0.2); // #00000033
$dark-grey: rgb(102, 102, 102); // #666666 // actionable
$dark-grey-blue: rgb(117, 134, 146); // #758692
$error: rgb(200, 16, 46); // #C8102E
$error-fill: rgb(250, 230, 233); // #FAE6E9
$grey-blue: rgb(197, 207, 218); // #C5CFDA
$light-blue: rgb(242, 247, 253); // #F2F7FD
$light-grey-blue: rgb(229, 241, 250); // #E5F1FA
$light-grey: rgb(238, 241, 245); // #EEF1F5 // disabled
$snow: rgb(249, 249, 249); // #F9F9F9
$light-white: rgb(244, 244, 244); // #F4F4F4
$very-light-grey: rgb(224, 224, 224); //E0E0E0
$medium-grey: rgb(195, 195, 195); // #C3C3C3
$success: rgb(0, 133, 60); // #00853C
$success-fill: rgb(229, 243, 235); // #E5F3EB
$visited: rgb(95, 37, 159); // #5F259F
$warning: rgb(252, 169, 35); // #FCA923
$warning-fill: rgb(255, 246, 232); // #FFF6E8
$white: rgb(255, 255, 255); // #FFFFFF
$yellow: rgb(254, 219, 0); // #FEDB00
$active-fill: rgb(229, 241, 250); // #E5F1FA
$assistive-text: $dark-grey-blue;
$background: $white;
$hazard: rgb(227, 89, 10); // #E3590A

// 2022 redesign colors
$extra-dark-grey: rgb(31, 31, 31); // #1F1F1F
$grey-100: rgb(224, 224, 224); // #E0E0E0
$red-700: rgb(205, 0, 0); // #CD0000

$drop-shadow-color: rgba(0, 0, 0, 0.14118);
$drop-shadow: 0 1px 3px $drop-shadow-color;
$backdrop-color: rgba(0.13333, 0.13333, 0.13333, 0.5);
$backdrop-drop-shadow: 0 16px 24px rgba(0, 0, 0, 0.2);

$focus-indicator-duration: 200ms;
$focus-indicator-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
$focus-indicator-width: 4px;
$focus-indicator-outline: $yellow;

$font-family-body: "Roboto", sans-serif;

$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

// Device Breakpoints - Typical use case is with media query mixins

// Generic
$max-phone-unitless: 767;
$max-tablet-unitless: 834;
$min-tablet-unitless: 768;
$min-desktop-unitless: 835;
$desktop-unitless: 835;

// Tablets
$ipad-unitless: 768;
$ipad-pro-unitless: 834;

// Phones
$iphone-5-unitless: 320;
$iphone-6-unitless: 375;
$iphone-x-unitless: 375;
$iphone-6-plus-unitless: 414;
$iphone-8-plus-unitless: 414;

// Generic
$max-phone: $max-phone-unitless * 1px;
$max-tablet: $max-tablet-unitless * 1px;
$min-tablet: $min-tablet-unitless * 1px;
$min-desktop: $min-desktop-unitless * 1px;
$desktop: $desktop-unitless * 1px;

// Tablets
$iPad: $ipad-unitless * 1px;
$iPad-Pro: $ipad-pro-unitless * 1px;

// Phones
$iPhone-5: $iphone-5-unitless * 1px;
$iPhone-6: $iphone-6-unitless * 1px;
$iPhone-X: $iphone-x-unitless * 1px;
$iPhone-6-Plus: $iphone-6-plus-unitless * 1px;
$iPhone-8-Plus: $iphone-8-plus-unitless * 1px;

// 2022 redesign breakpoints
$breakpoint-extra-small: 380px;
$breakpoint-small: 480px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-extra-large: 1280px;
$breakpoint-double-extra-large: 1536px;
