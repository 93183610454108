@import "../../../sass/abstracts/variables";
@import "../../../sass/abstracts/functions";

.wrapper {
  padding: 20px;
  text-align: left;
  width: auto;
  @media (min-width: $min-desktop) {
    width: 30vw;
  }
}
