@import "../../../sass/abstracts/mixins";

.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  display: flex;
  padding-top: 21px;
  align-items: center;
}

.pageNumber {
  color: rgba($black, 0.2);
  display: flex;
  align-items: center;
  letter-spacing: 1.87px;
  font-size: rem-calc(12);
  font-weight: 600;
  margin: 7px;

  &.clickable {
    color: $primary;

    html[data-theme="admin"] & {
      color: $primary-admin;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.currentPage {
  color: $white;
  margin-right: 7px;
  margin-left: 7px;
  font-size: rem-calc(12);
  font-weight: 600;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: $primary;
  padding: 0 7px;
  height: 22px;

  html[data-theme="admin"] & {
    background: $primary-admin;
  }
}

.chevron {
  display: inline-block;
  font-size: 1.125rem;
  position: relative;

  &.right {
    left: -6px;
  }
  &.left {
    right: -4px;
  }
}
