@charset "UTF-8";

@import "./abstracts/variables";
@import "./abstracts/functions";
@import "./abstracts/mixins";

@import "base/base";
@import "base/fonts";
@import "base/helpers";
@import "base/typography";
@import "components/icons";

@import "./vendors/normalize";
@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";

html,
body,
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  --toastify-toast-width: auto;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
button,
option,
select {
  font-family: "Roboto", sans-serif;
}

// Set outline to 0, an issue in MUI components and too many to fix manually
button {
  outline: 0;
  font-size: 16px;
}

button:focus {
  outline: 0;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// For tutorial dots
.slick-dots li {
  opacity: 1 !important;
}
.slick-dots li button:before {
  color: $very-light-grey !important;
  font-size: 16px !important;
  opacity: 1 !important;
}
.slick-dots li.slick-active button:before {
  color: #0172ce !important;
  font-size: 16px !important;
  opacity: 1 !important;
}

/* Screen reader only */
.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
