@import "shared/src/sass/abstracts/mixins";

.caret {
  background: transparent;
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid $white;
  margin-left: 4%;
}

.detailsBlockContainer {
  background: $active-fill;
  border-radius: 4px;
  border: 1px solid $primary;
  margin-top: 3.5rem;
  position: relative;

  html[data-theme="admin"] & {
    background: $primary-fill-admin;
    border: 1px solid $primary-admin;
  }
}

.detailsTitleContainer {
  background: $primary;
  border-radius: 4px;
  color: $white;
  display: flex;
  font-size: rem-calc(20);
  left: 5%;
  max-height: 7rem;
  padding: 10px;
  position: relative;
  top: -1.5rem;
  width: 90%;

  html[data-theme="admin"] & {
    background: $primary-admin;
  }
}

.detailsTitle {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detailsTitleIcon {
  color: $white;
  font-size: 1.8rem !important;
  margin: 0 1rem;

  @media (max-width: $max-phone) {
    margin: 0 0.4rem 0 0;
  }
}

.detailsWrapper {
  padding: 0 2rem 0;
}

.noteContainer {
  background: $white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
}

.subtitle {
  display: block;
  font-weight: 600;
  padding: 0.6rem 0.2rem;
}

.docWrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $light-grey;
  padding: 12.3px 12.3px 14.7px;
  margin-bottom: 10px;
}

.fileType {
  color: $dark-grey;
  letter-spacing: 0.48px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.descriptionText {
  font-size: 14px;
  letter-spacing: 0.56px;
  line-height: 19px;
}

.titleLink {
  font-weight: 700;
}

.iconAndSpecs {
  display: flex;
  flex-direction: row;
  margin-top: 14px;
}

.styledIcon {
  padding: 0;
  margin: 0 -4px;
}

.specs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.spec {
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 19px;
  margin-left: 14px;
}

.comment {
  width: 100%;
  padding-left: 0;
  font-size: 2.5rem;
}
