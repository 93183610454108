@import "./functions";
@import "./variables";

@mixin flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@mixin ellipsize-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
 * This should be used instead of 'focus-indicator-appear' when the focus indicator should show
 * on a descendant of the focused element. Or, when the ':focus' pseudo-class is otherwise
 * unneeded.
 *
 * $border-width - The width of an existing border on the element.
 * $box-shadows - Any other box shadows to display, apart from the focus indicator.
 */
@mixin focus-indicator-appear-external-focus(
  $border-width: 0,
  $box-shadows...
) {
  box-shadow: append($box-shadows, (0 0 0 4px - $border-width $yellow), comma);

  @if $border-width != 0 {
    border-color: $yellow;
  }
}

/*
 * $border-width - The width of an existing border on the element.
 */
@mixin focus-indicator-appear($border-width: 0) {
  &:focus {
    @include focus-indicator-appear-external-focus($border-width);
  }
}

/*
 * $border-width - The width of an existing border on the element.
 * $box-shadows - Any other box shadows to display, apart from the focus indicator.
 */
@mixin focus-indicator-disappear($border-width: 0, $box-shadows...) {
  box-shadow: append(
    $box-shadows,
    (0 0 0 6px - $border-width transparent),
    comma
  );
  transition-duration: $focus-indicator-duration;
  transition-timing-function: $focus-indicator-timing-function;

  @if $border-width != 0 {
    transition-property: border-color, box-shadow;
  } @else {
    transition-property: box-shadow;
  }

  &:focus {
    outline: none;
  }
}

/*
 * This should be used instead of 'focus-indicator-appear-inset' when the
 * focus indicator should show on a descendant of the focused element. Or,
 * when the ':focus' pseudo-class is otherwise unneeded.
 */
@mixin focus-indicator-appear-inset-external-focus($border-width: 0) {
  box-shadow: inset 0 0 0 4px - $border-width $yellow;

  @if $border-width != 0 {
    border-color: $yellow;
  }
}

/*
 * Use on the keyboard focused class for the element.
 */
@mixin focus-indicator-appear-inset($border-width: 0) {
  &:focus {
    @include focus-indicator-appear-inset-external-focus($border-width);
  }
}

/*
 * Use on the default styles of the element.
 */
@mixin focus-indicator-disappear-inset($border-width: 0) {
  box-shadow: inset 0 0 0 6px - $border-width transparent;
  transition-duration: $focus-indicator-duration;
  transition-timing-function: $focus-indicator-timing-function;

  @if $border-width != 0 {
    transition-property: border-color, box-shadow;
  } @else {
    transition-property: box-shadow;
  }

  &:focus {
    outline: none;
  }
}

@mixin font-body-large {
  font-family: $font-family-body;
  font-size: 1.25rem;
  font-weight: $font-weight-regular;
  line-height: 1.4;
}

@mixin font-body-medium {
  font-family: $font-family-body;
  font-size: 1rem;
  font-weight: $font-weight-medium;
  line-height: 1.5;
}

@mixin font-body-regular {
  font-family: $font-family-body;
  font-size: 1rem;
  font-weight: $font-weight-regular;
  line-height: 1.5;
}

@mixin font-heading-large {
  font-family: $font-family-body;
  font-size: 1.5rem;
  font-weight: $font-weight-medium;
  line-height: 1.33333;
}

@mixin font-medium-uppercase {
  font-family: $font-family-body;
  font-size: 1rem;
  font-weight: $font-weight-medium;
  letter-spacing: 1.44px;
  line-height: 1.5;
  text-transform: uppercase;
}

@mixin font-subheading {
  font-family: $font-family-body;
  font-size: 1.25rem;
  font-weight: $font-weight-medium;
  line-height: 1.4;
}

@mixin primary-outline-appear($border-width: 0, $box-shadows...) {
  box-shadow: append($box-shadows, (0 0 0 2px - $border-width $primary), comma);

  @if $border-width != 0 {
    border-color: $primary;
  }
}

@mixin font-size($size) {
  font-size: rem-calc($size);
}

@mixin small-regular {
  font-family: Roboto;
  font-size: rem-calc(14);
  font-weight: 400;
  letter-spacing: rem-calc(0.43);
  line-height: rem-calc(16);
}

// Media queries

// Generic
@mixin media-query-max-phone {
  @media (max-width: $max-phone) {
    @content;
  }
}

@mixin media-query-max-tablet {
  @media (max-width: $max-tablet) {
    @content;
  }
}

@mixin media-query-min-tablet {
  @media (min-width: $min-tablet) {
    @content;
  }
}

// Tablet
@mixin media-query-min-ipad {
  @media (min-width: $iPad) {
    @content;
  }
}

@mixin media-query-min-ipad-pro {
  @media (min-width: $iPad-Pro) {
    @content;
  }
}

// Phone
@mixin media-query-min-iphone-5 {
  @media (min-width: $iPhone-5) {
    @content;
  }
}

@mixin media-query-min-iphone-6 {
  @media (min-width: $iPhone-6) {
    @content;
  }
}

@mixin media-query-min-iphone-x {
  @media (min-width: $iPhone-X) {
    @content;
  }
}

@mixin media-query-min-iphone-6-plus {
  @media (min-width: $iPhone-6-Plus) {
    @content;
  }
}

@mixin media-query-min-iphone-8-plus {
  @media (min-width: $iPhone-8-Plus) {
    @content;
  }
}

// Desktop
@mixin media-query-max-desktop {
  @media (max-width: $desktop) {
    @content;
  }
}

@mixin media-query-min-desktop {
  @media (min-width: $min-desktop) {
    @content;
  }
}

@mixin reset-a {
  text-decoration: none;
}

@mixin reset-blockquote {
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
}

@mixin reset-button {
  background-color: transparent;
  border: none;
  padding: 0;
}

@mixin reset-heading {
  margin-block-end: 0;
  margin-block-start: 0;
}

@mixin reset-input {
  appearance: none;
  border: none;
  margin: 0;
}

@mixin reset-list {
  list-style: none;
  margin-block-end: 0;
  margin-block-start: 0;
  padding-inline-start: 0;
}

@mixin test-color {
    background: blue;

    html[data-theme="admin"] & {
        background: red;
    }
    // background: red;
}