@import "../../../sass/abstracts/mixins";
@import "../../../sass/abstracts/functions";
@import "shared/src/sass/abstracts/variables";

.profileCard {
  background-color: $white;
  color: $dark-grey;
  border: 1px solid $grey-100;
  border-radius: 4px;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px 0 20px;

  @media (min-width: $max-phone) {
    align-items: flex-start;
  }

  & .sectionLabel {
    color: $dark-grey;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.88px;
    margin-bottom: 4px;
  }

  & .dropdown {
    font-family: Roboto;
    font-size: 12px;
    margin-left: auto;
  }
}

.reportBody {
  display: grid;
  column-gap: 4px;
  grid-template-columns: 1fr;
  margin: 10px 16px 0 20px;
  padding: 10px 10px 10px 11px;
  align-items: center;

  @media (min-width: $max-phone) {
    grid-template-columns: 1fr 1fr;
  }
}

.reportSummary {
  min-height: 210px;
}

.viewAllReportsButton {
  @include reset-a;
  @include focus-indicator-appear;
  @include focus-indicator-disappear;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary;
  color: $white;
  font-size: rem-calc(13);
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 1.8461;
  margin-top: 10px;
  padding: 1px 12px;
  border-radius: 4px;
  cursor: pointer;

  html[data-theme="admin"] & {
    background-color: $primary-admin;
  }

  &:focus-visible,
  &:hover {
    background-color: $secondary;

    html[data-theme="admin"] & {
      background-color: $secondary-admin;
    }
  }
}

.footerLegend {
  color: $dark-grey;
  display: flex;
  font-weight: 400;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 6px 15px 12px 12px;

  @media (min-width: $max-phone) {
    padding-top: 14px;
  }

  & .legendItem {
    @include focus-indicator-appear;
    @include focus-indicator-disappear;
    @include reset-button;

    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    font-size: rem-calc(9);
    line-height: 1.6666;
    margin-right: auto;
    margin-bottom: 2px;
    padding: 4px 8px;

    &:focus-visible,
    &:hover {
      background-color: $light-white;
    }
  }

  & .legendItemColor {
    height: 12px;
    width: 12px;
    margin-right: 5px;
    border-width: 0px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.percentLineGraphRow {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  padding: 10px 16px 0 20px;

  & .percentLineGraph {
    flex-shrink: 1;
    flex-grow: 1;
    width: 0;
  }

  & .total {
    margin: 0 0 -4px 0;
    padding-right: 5px;

    & .label {
      color: $dark-grey;
      display: block;
      font-size: rem-calc(14);
      line-height: 1.2857;
    }

    & .count {
      color: $extra-dark-grey;
      font-size: rem-calc(36);
      font-weight: 500;
      letter-spacing: -1px;
      line-height: 1;
    }
  }
}

.pieChartWrapper {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

.pieChart {
  height: 180px;
  width: 180px;
}

.pieChartCenterText {
  &.overlay {
    width: 100%;
    position: absolute;
    bottom: 45px;
    padding-right: 20px;
    pointer-events: none;
  }

  .centerTextWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: rem-calc(24);
    padding-bottom: 10px;
  }

  .countText {
    font-size: 54px;
    font-weight: 300;
    color: #000000;
  }

  .statusText {
    color: $dark-grey;
  }
}

.table {
  border-collapse: collapse;
  color: $extra-dark-grey;
  font-size: rem-calc(14);
  font-weight: 700;
  line-height: 1.2857;
  width: 100%;

  & tbody {
    border-bottom: 1px solid $light-grey-blue;
  }

  & thead {
    border-bottom: 1px solid $light-grey-blue;
  }

  & td {
    padding: 4px 4px;
  }

  & tr:first-child td {
    padding-top: 10px;
  }

  & tr:last-child td {
    padding-bottom: 11px;
  }

  & .primaryHeading {
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
  }

  & .primaryHeadingName {
    padding: 0 8px;
    text-align: left;
  }

  & .reportTypeCount {
    font-size: rem-calc(18);
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 1;
  }

  & .reportTypeCountDescription {
    color: $dark-grey;
    font-size: rem-calc(12);
    line-height: 0.9375;
  }

  & .typeCount {
    text-align: right;
  }

  & .typeDescription {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    min-width: 0;
    font-size: rem-calc(11);
    font-weight: 400;
    line-height: 1.3636;
    color: $dark-grey;
  }

  & .typePercentage {
    width: 46px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
