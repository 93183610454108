@import "../../../../sass/abstracts/mixins";

.recentDocuments {
  @media screen and (max-width: $max-phone) {
    padding: 0 15px;
  }

  .cards {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    margin: 1rem 0;
    align-items: flex-start;
    user-select: none;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px;
        background: transparent;
        display: none;
    }
  }

  .card {
    border: 1px solid $grey-100;
    border-top-width: 3px;
    border-radius: 5px;
    padding: 15px;
    width: 170px;
    margin-right: 28px;
    white-space: nowrap;
    background-color: $white;
    cursor: pointer;

    &.new {
      border-top-color: $primary;
      
      html[data-theme="admin"] & {  
        border-top-color: $primary-admin;
      }

      .statusPill {
        color: $primary;

        html[data-theme="admin"] & {
          color: $primary-admin;
        }
      }
    }
    &.inProgress {
      border-top-color: $warning;

      .statusPill {
        color: $warning;
      }
    }
    &.submitted {
      border-top-color: $success;

      .statusPill {
        color: $success;
      }
    }

    &:hover {
      box-shadow: 0px 5px 5px $grey-100;
    }

    .cardHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .actionMenu {
        background: none;
        border: none;
        position: relative;
        left: 1rem;
        cursor: pointer;

        i {
          color: $dark-grey;
          font-size: 1.5rem;
          margin: 0;
          position: relative;
          top: 2px;
        }
      }

      .statusPill {
        width: 72px;
        height: 22px;
        font-size: 12px;
        display: flex;
        align-items: center;
        font-weight: 700;
      }
    }

    .title {
      color: $extra-dark-grey;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .subtitle {
      color: $dark-grey;
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      padding: 10px 0;

      .id {
        flex-shrink: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 4px;
      }

      .date {
        flex-shrink: 0;
      }
    }

    .mapContent {
      margin-top: 10px;
      height: 52px;
      background: $light-blue;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      html[data-theme="admin"] & {
        background: $primary-fill-admin;
      }
      
        
      img {
        border: 1px solid $grey-100;
        border-radius: 4px;
      }
      .noMapAvailable {
        color: $primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 2px;
        text-transform: uppercase;
        white-space: normal;
        text-align: center;

        html[data-theme="admin"] & {
          color: $primary-admin;
        }
      }
    }

    .mapIcon {
      background: none;
    }

    .mapImage {
      border-radius: 4;
      width: 100%;
      height: 100%;
    }
  }
}