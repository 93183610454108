@import "shared/src/sass/abstracts/variables";

.filterDrawer {
  .header {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $dark-grey;
    
    .back {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }

  .filterOptionsDrawer {
    border-top: 1px solid $light-grey-blue;
    padding-top: 20px;

    > div:first-child {
        display: none;
    }

    .mobilePhone {
      flex-direction: column;
      
      > div {
        margin: 0 0 10px;
      }
    }
  }

  .filterButtonsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    button {
      display: flex;
      padding: 7px 12px;
      justify-content: center;
      align-items: center;
      gap: 5px;
      align-self: stretch;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      
      &.showResults {
        margin-bottom: 20px;

        .loadingCount {
          color: $white;
          font-size: 14px;
        }
      }

      &.reset {
        border: 1px solid $primary;

        html[data-theme="admin"] & {
          border: 1px solid $primary-admin;
        }
      }
    }
  }
}
 