@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.ListItemOption {
  margin-bottom: rem-calc(3);
}

.IconForEndAdornment {
  color: $dark-grey;
  &:hover {
    cursor: pointer;
  }
}

.LabelWrapper {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.Label {
  flex: 1;
}

.Wrapper {
  border-radius: 4px;
  border: 1px solid rgb(24, 24, 24);
  padding: 1rem 0.875rem; // This is rem to match other inputs, not what I think it should be -- GK
}

.ActionLink {
  color: $primary;
  cursor: pointer;
  margin: 0 5px 5px 5px;
  text-decoration: underline;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }
}

.AssistiveText {
  color: $medium-grey;
}
