@import "../../sass/abstracts/mixins";

.mainContainer {
  width: 100%;

  @media screen and (max-width: $max-phone) {
    padding: 0 15px;
  }

  .documentsTitle {
    display: flex;
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.5px;
    justify-content: space-between;

    .titleText {
      padding-top: 10px;
    }

    > span:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .newDocumentButtonWrapper {
      .newDocumentButton {
        font-size: 13px;
        padding-left: 4px;
      }

      @media screen and (max-width: $max-phone) {
        display: none;
      }
    }
  }
}
  
.documentsListHeader {
  display: flex;
  color: $dark-grey;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media screen and (max-width: $max-phone) {
    &.documents {
      padding: 0 15px;
    }
  }
}

.formTypeIcon {
  border-radius: 4px;
  font-size: rem-calc(20);
  padding: 5px 5px 5px 0;
}