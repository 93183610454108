@import "../../../sass/abstracts/mixins";

.container {
  position: fixed;
  bottom: 54px;
  right: 0;
  z-index: 11999;

  .button {
    background-color: $white;
    border-radius: 5px 0 0 5px;
    border: 1px solid $grey-100;
    color: $primary;
    padding: 0;
    width: 50px;
    height: 50px;
    text-align: center;

    html[data-theme="admin"] & {
      background-color: $white;
      color: $primary-admin;
    }
    
    &:hover {
      background-color: $primary;
      color: $primary-fill;

      html[data-theme="admin"] & {
        background-color: $primary-admin;
        color: $primary-fill-admin;
      }
    }
  }

  @media screen and (max-width: $breakpoint-large) {
    bottom: 96px;
  }
}
