@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/mixins";

.h1 {
  font-size: rem-calc(24);
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 1;
}

.medium {
  font-weight: 500;
}

.png {
  margin-top: rem-calc(16);
}

.link {
  color: $primary;
  text-decoration: underline;
  cursor: pointer;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }
}

.columnDiv {
  display: flex;
  flex-direction: column;
}

.pointerDiv {
  cursor: pointer;
  height: 17px;
}

.darkIcon {
  color: $dark-grey;
}

.smallText {
  font-size: rem-calc(14);
}

.searchBarContainer {
  margin-bottom: rem-calc(26);
}

.title {
  font-weight: 400;
  font-size: rem-calc(36);
  letter-spacing: rem-calc(0.63);
  margin-bottom: rem-calc(24);
}

.smallTitle {
  display: block;
  font-size: rem-calc(12);
  letter-spacing: rem-calc(1.88);
  line-height: rem-calc(16);
  font-weight: 500;
  color: $dark-grey;
  margin-bottom: rem-calc(8);
}

.titleWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
}

.sectionHeader {
  display: block;
  font-size: rem-calc(36);
  color: $primary;
  letter-spacing: rem-calc(0.63);
  line-height: rem-calc(48);
  margin-bottom: rem-calc(24);

  html[data-theme="admin"] & {
    color: $primary-admin;
  }

  &Disabled {
    color: $light-grey;
  }
}

.formSection {
  margin-left: rem-calc(39);
}

.blockSpan {
  display: block;
}

.subtitle {
  display: block;
  font-size: rem-calc(16);
  color: $dark-grey;
  letter-spacing: rem-calc(0.15);
  line-height: rem-calc(24);
  margin-bottom: rem-calc(24);
}

.mockFieldLabel {
  display: block;
  font-size: rem-calc(20);
  font-weight: 500;
  letter-spacing: rem-calc(0.19);
  line-height: rem-calc(26);
}

.mockFieldValue {
  display: block;
  padding: rem-calc(8) rem-calc(16) 0;
}

.inputWithNote {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input {
  width: rem-calc(398);
}

.noteContainer {
  display: flex;
  flex-direction: column;
  margin-top: rem-calc(39);
}

.note {
  height: max-content;
  display: flex;
  flex-direction: column;
  width: rem-calc(469);
  background-color: $active-fill;
  border-radius: rem-calc(4);
  color: $dark-grey;
  font-size: rem-calc(14);
  letter-spacing: rem-calc(0.13);
  line-height: rem-calc(21);
  padding: rem-calc(8);
  margin-bottom: rem-calc(16);

  html[data-theme="admin"] & {
    background-color: $primary-fill-admin;
  }
}

.select {
  width: rem-calc(398);
}

.editButton {
  display: flex;
  height: rem-calc(48);
  width: rem-calc(76);
  margin: 0;
  padding: 0;
  text-align: center;
  justify-content: center;
}

.cancelButton {
  margin-right: 28px;
  padding: 14px 24px;
}

.modalAlert {
  margin-bottom: 33px;
}

.modalHeader {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 28px;
}

.modalHeading {
  align-self: center;
  color: $black;
  display: block;
  font-size: rem-calc(24);
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
}

.modalText {
  margin-bottom: 36px;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 48px;
}

.toggleButton {
  @include focus-indicator-appear(1px);
  @include reset-button;

  align-items: center;
  border: 1px solid $light-grey;
  border-radius: 6px;
  color: $dark-grey;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: rem-calc(12);
  font-weight: 500;
  height: 154px;
  letter-spacing: rem-calc(1.88);
  line-height: 1.1666;
  justify-content: center;
  margin-right: 8px;
  text-align: center;
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: $light-blue;
    cursor: pointer;
  }

  &Icon {
    color: $dark-grey;
    margin-bottom: 17px;

    &Selected {
      color: $primary;

      html[data-theme="admin"] & {
        color: $primary-admin;
      }
    }
  }

  &Selected {
    background-color: $light-blue;
    border: 1px solid $primary;

    html[data-theme="admin"] & {
      border: 1px solid $primary-admin;
    }
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-bottom: 12px;
}

.tableWrapper {
  border: 1px solid $light-grey;
  border-radius: rem-calc(4);
  width: 100%;
  padding: rem-calc(16);
  background-color: $light-white;
  margin-bottom: rem-calc(24);
  overflow-x: scroll;
  overflow-y: hidden;
}

.th {
  width: rem-calc(219);
  min-width: rem-calc(219);
  margin-right: rem-calc(43);
}

.td {
  font-size: rem-calc(14);
  color: $dark-grey;
  width: rem-calc(262);
  min-width: rem-calc(262);
  padding-right: rem-calc(43);
  border-bottom: 1px solid $light-grey;
  height: rem-calc(46);
}

.dataRow {
  display: flex;
  flex-direction: row;
  margin-bottom: rem-calc(17);
}

.metric {
  display: flex;
  flex-direction: column;
  margin-bottom: rem-calc(16);
}

.metricColumn {
  display: flex;
  flex-direction: column;
  margin-right: rem-calc(60);
}

.dataItem {
  display: block;
  font-size: rem-calc(16);
  letter-spacing: rem-calc(0.15);
  line-height: rem-calc(24);
}

.previewHeader {
  display: block;
  font-size: rem-calc(20);
  letter-spacing: rem-calc(0.19);
  line-height: rem-calc(24);
  font-weight: 500;
  margin-bottom: rem-calc(16);
}

.tableButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addButton {
  letter-spacing: 1.44px;
  padding-left: 22px;
  padding-right: 22px;

  &Icon {
    margin-right: 6px;
  }
}

.downloadButton {
  margin: 0 0 rem-calc(24) !important;
}

// added button. to be more specific then rules being overridden...
button.importButton {
  display: initial;
  width: rem-calc(248);
  margin: 0 0 1.5rem !important;
  padding: rem-calc(8) 0;
  text-align: right;
}

.dataSetTitle {
  color: $black;
}

.dataSetsTitleWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}

.dataSetDescription {
  font-size: rem-calc(14);
  line-height: 1.143;
  padding-top: 5px;
}

.status {
  &Published {
    color: $success !important;
  }

  &Unpublished {
    color: $error !important;
  }
}

.titleCell {
  width: 380px;
}
