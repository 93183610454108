@import "shared/src/sass/abstracts/variables";

.pieChartWrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;


    .pieChart {
        height: 65px;
        width: 65px;
    }

    .pieChartCenterText {
        &.overlay {
            width: 100%;
            position: absolute;
            bottom: 14px;
            pointer-events: none;
        }

        .centerTextWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: rem-calc(24);
            padding-bottom: 10px;
        }

        .countText {
            color: $primary;
            text-align: center;
            font-size: 10px;
            font-weight: 700;
            line-height: 15px;

            html[data-theme="admin"] & {
                color: $primary-admin;
            }
        }
    }
}