@import "shared/src/sass/abstracts/variables";

.icon {
  color: $dark-grey;

  &Error {
    color: $error;
  }

  &Success {
    color: $success;
  }
}

.label {
  flex-grow: 1;
  font-size: 0.875rem;
  letter-spacing: 0.24px;
  line-height: 1;
  padding: 0 10px;
}

.option {
  align-items: center;
  color: $dark-grey;
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 4px;
  padding: 8px 24px 8px 20px;
  user-select: none;

  &:hover {
    background-color: $light-blue;
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &Error {
    color: $error;
  }

  &Success {
    color: $success;
  }
}

.optionSelected {
  background-color: $light-grey;

  &:hover {
    background-color: $light-grey;
  }
}
