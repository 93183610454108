@import "../../../sass/abstracts/mixins";

.percentChartWrapper {
  margin-top: 16px;
}

.percentChartRow {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px 0;

  &:first-of-type {
    margin-top: 0px;
  }

  &Value {
    width: calc(4ch + 4.5px); // Need to account for letter spacing
    @include font-size(16);
    letter-spacing: 1.44px;
    line-height: rem-calc(24);
    font-weight: 500;
    color: $dark-grey-blue;
    text-align: right;
  }
}

.percentChartBar {
  position: relative;
  width: calc(100% - (4ch + 4.5px)); // Take up remaining space after row value
  border-radius: 4px;
  border: 1px solid $light-grey;
  background-color: $light-blue;
  margin-left: 5px;
  height: 35px;

  &Fill {
    position: absolute;
    left: 0;
    top: 0;
    background-color: tint(rgb(1, 114, 206), 80%);
    border-radius: 4px 0 0 4px;
    height: 33px; // Height of bar minus its top and bottom borders

    &Full {
      border-radius: 4px; // Full bar should have rounded corners on all sides
    }
  }

  &Label {
    position: absolute;
    left: 10px;
    top: 5.5px;
    @include font-size(16);
    line-height: rem-calc(24);
    max-width: calc(60% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &Value {
    position: absolute;
    right: 10px;
    top: 5.5px;
    @include font-size(16);
    line-height: rem-calc(24);
    font-weight: 500;
  }
}
