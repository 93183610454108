@import "shared/src/sass/abstracts/mixins";
@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";
.viewRelated {
  display: flex;
}

.loader {
  padding: 0;
  margin-left: 8px;
  width: 20px;
}

.sidebarDrawerHeader {
  color: $black;
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.26px;
}

.sidebarDrawerBody {
  color: $black;
  font-size: remCalc(14);
  letter-spacing: 0.24px;
}

.navLabel {
  font-size: rem-calc(14);
  color: $dark-grey;
  font-weight: 400;
  letter-spacing: rem-calc(0.24);
}

.divider {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
}
