@import "../../../../sass/abstracts/variables";

.signature {
  width: 600px;
  max-width: 100%;
  padding: 10px 0;

  .labelRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $max-phone) {
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .maxWidth {
    @media (max-width: $max-tablet) {
      max-width: 500px;
    }

    @media (max-width: $max-phone) {
      max-width: 400px;
    }
  }

  .clearButton {
    color: $error;
    font-size: 16px;
    text-decoration: underline;
    border: none;
    background: none;
    padding: 0 0.5rem;

    @media (max-width: $max-tablet) {
      font-size: 21px;
    }

    @media (max-width: $max-phone) {
      font-size: 14px;
    }
  }

  .submittedImageWrap {
    height: 56px;
    border: 1px solid $black;
    border-radius: 4px;

    @media (max-width: $max-tablet) {
      height: 84px;
    }

    @media (max-width: $max-phone) {
      height: 56px;
    }
  }

  .image {
    object-fit: contain;
    height: 100%;
    width: auto;
  }
}

.signaturePad {
  width: 600px;
  height: 250px;
  border: 1px solid $dark-grey;
  border-radius: 4px;
  position: relative;

  @media (max-width: $max-phone) {
    width: 80vw;
  }

  .centerElement {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .clearButton {
    position: absolute;
    width: auto;
    height: 2.5rem;
    font-size: 0.75rem;
    font-weight: 700;
    z-index: 3;
    left: 16px;
    margin-top: 5px;
  }

  .clearButtonLabel {
    color: $error;
  }

  .confirmButton {
    position: absolute !important;
    right: 16px;
    width: auto;
    font-size: 0.75rem;
    z-index: 4;
    height: 2.5rem !important;
    margin-top: 5px !important;
  }

  .canvas {
    width: 600px;
    height: 250px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .bottomLine {
    width: 552px; // 600 - 48
    height: 214px; // 250 - 36
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid $dark-grey;
    margin-left: 24px;
    margin-right: 24px;
  }

  .signHereIcon {
    font-size: 50px;
    color: $black;
  }

  .signHerelabel {
    color: $dark-grey;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}
