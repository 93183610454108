@import "../../../sass/abstracts/mixins";

.tableRow {
  border-bottom: 1px solid $light-grey;
  height: rem-calc(75);

  @media print {
    page-break-inside: avoid; 
    page-break-after: auto
  }

  &:focus-visible {
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 0 0 rem-calc(4) $focus-indicator-outline inset;
    background-color: $light-blue;
  }

  &:active {
    box-shadow: none;
  }

  &.clickable {
    cursor: pointer;

    &:active {
      background-color: $light-grey;
    }

    &:hover {
      background-color: $light-blue;

      html[data-theme="admin"] & {
        background-color: $primary-fill-admin;
      }
    }
  }
}
