@import "shared/src/sass/abstracts/variables";

.noRecentsDataArea {
  color: $primary;
  display: flex;
  flex-direction: column;
  align-items: center;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }

  .noDocumentsText {
    font-weight: 500;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 2px;
    margin: 40px 0;
  }
}

.addButton {
  letter-spacing: 1.44px;
  padding-left: 22px;
  padding-right: 22px;

  &Icon {
    margin-right: 6px;
  }
}

.addIcon {
  color: $active-fill;
  margin-top: 20px;
}

.recentsListContainer {
  display: block;
  max-height: 280px;
  overflow: auto;
}

.powerlines {
  width: 100%;
  height: auto;
}

.loader {
  min-height: 280px;
}
