@import "../../../sass/abstracts/mixins";

.fullName {
  color: #000000;
  font-size: rem-calc(14);
  font-weight: 700;
  line-height: 1.2857;
}

.header {
  align-items: center;
  border-bottom: 1px solid $active-fill;
  display: flex;
  flex-direction: row;
  margin: 0 16px;
  padding: 18px 0;

  &Content {
    padding: 0 9px;
  }
}

.initials {
  align-items: center;
  border: 1px solid $grey-100;
  border-radius: 50px;
  color: $dark-grey;
  display: flex;
  font-size: rem-calc(20);
  font-weight: 900;
  height: 50px;
  justify-content: center;
  letter-spacing: -1px;
  line-height: 1.375;
  text-transform: capitalize;
  width: 50px;
}

.viewProfileLink {
  @include reset-a;
  @include focus-indicator-appear;
  @include focus-indicator-disappear;

  border-radius: 4px;
  color: $primary;
  font-size: rem-calc(14);
  line-height: 1.2857;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }

  &:hover {
    color: $secondary;

    html[data-theme="admin"] & {
      color: $secondary-admin;
    }
  }
}
