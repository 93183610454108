@import "../../../sass/abstracts/variables";

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  gap: 10px;
}

.dateRangeWrapper {
  margin-bottom: 32px;
}

.author {
  border: 0;
  padding: 4px 0;
  min-width: 185px;

  &:hover {
    border: 0;
  }
  &:focus-within {
    border-bottom: 1px solid $light-grey;
    border-radius: 0;
    &:hover {
      border-bottom: 1px solid $dark-grey;
    }
  }
}
