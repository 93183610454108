@charset "UTF-8";

@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/variables";

.dateRangeRow {
  display: flex;
  padding-top: 8px;
}

.summaryWrapper {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    background: transparent;
    display: none;
  }
}

.filterWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-top: 24px;
  gap: 10px;
}

.selectWrapper {
  :first-of-type {
    margin-left: 0;
  }

  :last-of-type {
    margin-right: 0;
  }
}

.subText {
  display: block;
  color: $dark-grey;
}

.reportLink {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: $primary;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }

  &Icon {
    margin: 0;
    font-size: 1.2rem;
  }

  &:hover {
    text-decoration: none;
  }
}
.formTypesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
}

.drawerTitle {
  display: flex;
  align-items: center;
}

.drawerTitleText {
  flex-grow: 1;
  color: $black;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-self: center;
  text-align: center;
}

.titleIcon {
  padding-left: 12px;
}