@import "../../../sass/abstracts/variables";
@import "../../../sass/abstracts/functions";

.actionButton {
  margin-left: 8px;
}

.downloadResultsLoader {
  padding: 12px;
}

.icon {
  color: $primary;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }
}

.summaryWrapper {
    display: flex;
    align-items: flex-end;
}

.summaryExports {
  align-items: center;
  color: $primary;
  cursor: pointer;
  display: flex;
  font-size: rem-calc(25);

  html[data-theme="admin"] & {
    color: $primary-admin;
  }

  @media print {
    display: none;
  }
}

.showAll {
  margin: 0;
  margin-right: 15px;
  cursor: pointer;
  color: $primary;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }
}

.summaryText {
  color: $black;
  font-size: 1rem;
  font-style: italic;
}

.wrapper {
  align-items: center;
  background: $light-grey;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 67px;
  padding: 10px 16px 10px 24px;

  @media print {
    padding: 0;
    min-height: auto;
  }
}
