@import "../../../../../sass/abstracts/mixins";

.documentDetailsWrapper {
  .title {
    @include reset-heading;
    @include font-size(20);
    line-height: rem-calc(28);
    font-weight: 500;
  }

  .subtitle {
    @include reset-blockquote;
    @include font-size(14);
    line-height: rem-calc(24);
    margin-bottom: 16px;
  }
}

.deleteDocumentButtonWrapper {
  margin: 40px 0;
  display: flex;
  justify-content: center;

  & button {
    font-weight: 600;
  }
}

.documentHistoryTable {
  padding: 1rem 0;

  table {
    width: 100%;

    th,
    td {
      padding: 0 4px;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        text-align: right;
        padding-right: 0;
      }
    }

    tr {
      border-bottom-width: 2px;
    }
  }

  .historyDateCell {
    display: flex;
    flex-direction: column;

    & span {
      line-height: rem-calc(24);
    }
  }

  a {
    text-decoration: underline;
  }

  .noData,
  .time {
    color: $dark-grey-blue;
  }

  .noData {
    padding-left: 16px;
  }
}

