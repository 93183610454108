@import "../../../sass/abstracts/mixins";

.tabContainer {
  background-color: $white;
  border: 1px solid $grey-100;
  border-radius: 4px;
}

.tab {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
}

.tabList {
  border: none;
}

.tabActive {
  border: none;

  html[data-theme="admin"] & {
    border: none;
  }
}

.tabLabel {
  line-height: 2rem;
  color: $dark-grey;
  font-size: 12px;

  &Active {
    color: $primary;
    border-bottom: $primary solid 3px;

    html[data-theme="admin"] & {
      color: $primary-admin;
      border-bottom: $primary-admin solid 3px;
    }
  }

}
