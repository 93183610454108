@import "shared/src/sass/abstracts/variables";

.actionsCell {
  width: 64px;
}

.icon {
  color: $primary;
  margin-right: 13px;

  html[data-theme="admin"] & {
    color: $primary-admin;
  }
}

.nameWrapper {
  align-items: center;
  color: $black;
  display: flex;
  letter-spacing: 0.15px;
  line-height: 1.1875;
}

.row {
  height: 64px;
}
