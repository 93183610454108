@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.modalPromptTitle {
  color: $black;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 18px;
}

.deleteFormTypeButtonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin: 48px 0 0 0;
}

.buttonMargin {
  margin: 0 24px;
}
