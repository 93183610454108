@charset "UTF-8";

@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/variables";

.printReport {
  h1 {
    font-weight: 400;
    font-size: remCalc(36);
    letter-spacing: remCalc(0.63);
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .pageBreak {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
