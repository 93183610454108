@import "shared/src/sass/abstracts/variables";

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  button:disabled {
    color: $white
  }

  .left {
    button {
      .backIcon {
        margin-right: 5px;
      }

      @media screen and (max-width: 440px) {
        &.backButton {
          padding-left: 0;
          padding-right: 0;

          .backText {
            display: none;
          }
        }
      }
    }
  }

  .right {
    button {
      margin-left: 20px;

      .saveIcon {
        margin-right: 5px;
      }

      @media screen and (max-width: 600px) {
        &.saveButton {
          padding-left: 0;
          padding-right: 0;
          margin-left: -30px;
          
          .saveText {
            display: none;
          }
        }
      }
      .nextIcon {
        
      }

      
      .submitIcon {
        
      }

      @media screen and (max-width: 440px) {
        &.nextButton {
          padding-left: 0;
          padding-right: 0;

          .nextText {
            display: none;
          }
        }
      }
    }

    .notValidated {
      background-color: $light-grey;
      cursor: help;
    }
  }
  
  @media screen and (max-width: 600px) {
    .left {
      width: 50%;
    }
    .right {
      display: flex;
      justify-content: space-between;
      width: 50%;
    }
  }
}