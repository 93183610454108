@import "../../../sass/abstracts/mixins";

.mainBlock {
    width: 100%;
    padding: 10px 0;
}

.formActions {
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding-bottom: 2.125rem;
  margin-bottom: 2.125rem;
  z-index: 10; // 10 for Submit/Save buttons to overlap document items on ErrorScroll
  &.error {
    position: sticky;
    bottom: 0;
  }
}

.formSection {
  padding: 20px 0 0;

  &.formSectionError {
    border: 2px solid $error;
    padding: 20px;
    background-color: $error-fill;
  }

  .title {
    color: $black;
    font-weight: 400;
    font-size: rem-calc(28);
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  .separator {
    height: rem-calc(1);
    width: 100%;
    background-color: $medium-grey;
    margin-top: 1.5rem;
  }
}

.formSectionProperties {
  margin-bottom: rem-calc(50);
  background: $light-white;
  border-radius: rem-calc(10);
  padding: rem-calc(10);

  @media (min-width: $min-desktop-unitless) {
    padding: rem-calc(10) 0;
    border-radius: 1rem;

    display: flex;
    width: 100%;
    justify-content: space-between;

    background: $light-white;
    padding: rem-calc(10);
  }

  @media (min-width: $min-tablet-unitless) {
    display: flex;
    width: 100%;
  }

  @media (max-width: $max-phone-unitless) {
    margin-bottom: rem-calc(14);
  }

  .title {
    margin-bottom: rem-calc(9);
    font-size: rem-calc(20);
    font-weight: 500;

    @media (min-width: $min-tablet-unitless) {
      max-width: 20%;
    }
  }
  .header {
    font-size: rem-calc(14);

    @media (min-width: $min-tablet-unitless) {
      margin-top: rem-calc(5);
    }
  }
}

// .formQuestion {
//   margin-bottom: 1rem;

//   @media (min-width: $max-tablet) {
//     margin-bottom: rem-calc(21);
//   }
// }

.contentBanner {
  padding: rem-calc(14) 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.contentBlock {
  margin-bottom: 1rem;
}

div.decisionBlock {
  padding: 10px 20px;
  background-color: $active-fill;
  border: 1px solid $primary;
  border-radius: 0.25rem;
  margin: 10px 0;

  html[data-theme="admin"] & {
    border-color: $primary-admin;
  }
}

div.decisionBlock + div.decisionBlock {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  z-index: 1;
  top: -15px;
}
