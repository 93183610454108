@import "shared/src/sass/abstracts/variables";

.nonActivated {
  color: #f9ac19;
}

.active {
  color: #00853c;
}

.deactivated {
  color: #f91919;
}

.selectedRow {
  background: rgba($primary, 0.1);
  
  html[data-theme="admin"] & {
    background: rgba($primary-admin, 0.1);
  }

  .icon {
    margin-left: 12px;
    color: $primary;

    html[data-theme="admin"] & {
      color: $primary-admin;
    }
  }

  .itemText {
    color: $black;
    margin-left: 8px;
  }
}

.deselectedRow {
  .itemText {
    color: $dark-grey;
    margin-left: 40px;
  }
}

.menuRow {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  padding: 0;
  margin: 0 -22px;
  height: 37px;

  .itemText {
    flex: 1;
    font-size: 0.875rem;
    letter-spacing: 0.5px;
    line-height: 1.35;
  }
}

.popperWrapperNoArrow {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 22px;
  position: relative;
}

.actionButton {
  font-size: 0.875rem;
  height: 41px;
  padding-left: 17px;
  padding-right: 17px;
}

.actionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 13px;
}

.resetButton {
  margin-left: 11px;
}
