@import "../../../sass/abstracts/mixins";

.dateArea {
  grid-area: date;
}

.dayOfWeek {
  display: none;

  @media (min-width: $breakpoint-large) {
    display: inline;
  }
}

.heading {
  font-size: rem-calc(26);
  font-weight: 500;
  grid-area: title;
  letter-spacing: -0.5px;
  line-height: 0.9231;
  margin-bottom: 8px;
  margin-top: 0;
}

.iconArea {
  display: flex;
  flex-direction: row;
  grid-area: icon;
  margin-top: 18px;

  @media (min-width: $breakpoint-large) {
    margin-left: 8px;
    margin-top: 0;
  }
}

.importantText {
  font-weight: 500;

  @media (min-width: $breakpoint-large) {
    font-size: rem-calc(16);
    letter-spacing: -0.25px;
    line-height: 1.125;
  }
}

.loader {
  grid-column-end: 3;
  grid-column-start: 1;
  grid-row-end: 4;
  grid-row-start: 2;

  @media (min-width: $breakpoint-large) {
    grid-column-end: 6;
    grid-column-start: 2;
    grid-row-end: 3;
    grid-row-start: 1;
  }
}

.locationArea {
  grid-area: location;
  text-align: right;

  @media (min-width: $breakpoint-large) {
    margin-left: 40px;
  }
}

.metricsArea {
  grid-area: metrics;
  margin-top: 15px;
  text-align: right;

  @media (min-width: $breakpoint-large) {
    margin-left: 8px;
    margin-top: 0;
    text-align: left;
  }
}

.noWeatherDataArea {
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-column-end: 3;
  grid-column-start: 1;
  grid-row-end: 4;
  grid-row-start: 3;
  justify-content: space-between;

  @media (min-width: $breakpoint-large) {
    grid-column-end: 6;
    grid-column-start: 3;
    grid-row-end: 3;
    grid-row-start: 1;
    justify-content: normal;
    text-align: right;
  }
}

.noWeatherDataIcon {
  margin-left: 12px;
}

.secondaryText {
  color: $dark-grey;
}

.temperature {
  color: #000000;
  font-size: rem-calc(54);
  font-weight: 300;
  line-height: 1;
  padding-left: 15px;
}

.temperatureUnit {
  font-size: rem-calc(18);
  font-weight: 500;
  vertical-align: 140%;
}

.welcomeUserContainer {
  background-color: $white;
  border: 1px solid $grey-100;
  border-radius: 5px;
  color: #1f1f1f;
  display: grid;
  font-size: rem-calc(14);
  grid-template-areas:
    "title title"
    "date location"
    "icon metrics";
  grid-template-columns: 1fr 1fr;
  line-height: 1.2857;
  padding: 28px;

  & p {
    margin: 0;
  }

  @media (min-width: $breakpoint-large) {
    grid-template-areas:
      "title   x icon metrics location"
      "date    x icon metrics location";
    grid-template-columns: auto 1fr auto auto auto;
    grid-template-rows: auto 1fr;
    padding: 30px;
  }
}
