@import "../../../sass/abstracts/mixins";

.document {
  display: flex;
  flex-direction: column;

  &Details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    padding: 18px 21px;
    height: 100%;

    &Location {
      font-weight: 700;
      padding-bottom: 14px;
    }
  }
}

.listItemDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: $active-fill solid 1px;

  &NoMap {
    grid-template-columns: 1fr;
    background-color: $active-fill;
  }
}

.map {
  height: 100%;
}

.viewDocumentButton {
  @include reset-a;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary;
  color: $white;
  font-size: rem-calc(13);
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 1.8461;
  padding: 5px 21px;
  cursor: pointer;

  html[data-theme="admin"] & {
    background-color: $primary-admin;
  }

  &:focus {
    box-shadow: 0 0 0 $focus-indicator-width $yellow inset;
    outline: none;
  }

  &:focus,
  &:hover {
    background-color: $secondary;

    html[data-theme="admin"] & {
      background-color: $secondary-admin;
    }
  }
}
